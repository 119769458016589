import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Button, Table, Form, Pagination, Alert } from 'react-bootstrap';
import axios from 'axios';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {CREATE_ATTENDANCE_URL, EDIT_ATTENDANCE_URL} from '../apiConfig';
import Spinner from 'react-bootstrap/Spinner';


const AttendanceModal = ({ show, handleClose, student }) => {
    const [attendances, setAttendances] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showAddAttendance, setShowAddAttendance] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editAttendanceId, setEditAttendanceId] = useState(null);
    const [loading, setLoading] = useState(false);
    // const [newAttendance, setNewAttendance] = useState(localStorage.getItem('newAttendance') || 
    //         {
    //         class_name: 'Milki',
    //         observations: '',})

    const [newAttendance, setNewAttendance] = useState({
        class_name: localStorage.getItem('newAttendance') || 'Milki',
        observations: '',
    });
    const [formError, setFormError] = useState('');

    const attendanceOptions = ['Prospección', 'Milki', 'Growth', 'Dominator', 'Soporte Campañas'];
    const itemsPerPage = 10;

    const token = localStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

    useEffect(() => {
        if (student) {
            fetchAttendance();
        }
    }, [student, currentPage]);

    // Fetch attendance data from the server (with pagination)
    const fetchAttendance = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${CREATE_ATTENDANCE_URL.replace('<int:user_id>', student.id)}?page=${currentPage}&limit=${itemsPerPage}/`, { headers });

            if (response.status === 200 && response.data.length > 0) {
                setAttendances(response.data); // Asigna el array de datos directamente
                setTotalPages(Math.ceil(response.data.length / itemsPerPage)); // Ajusta el total de páginas
            } else {
                // Si no hay registros, manejarlo sin error
                setAttendances([]);
                setTotalPages(0);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized, removing token');
                localStorage.removeItem('token'); // Eliminar token si es un 401
                alert('Debes iniciar sesión nuevamente.');
                window.location.href = '/'; // Redirigir a /login
        
            } else {
            console.error('Error fetching student data:', error);
        }
        }
        finally{
            setLoading(false);
        }
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAttendance({
            ...newAttendance,
            [name]: value,
        });
        if ('class_name' === name )
        {localStorage.setItem('newAttendance', value);}        
        console.log(name)
    };

    const handleAddAttendance = async () => {
        setFormError('');
        if (!newAttendance.class_name) {
            setFormError('Por favor, selecciona una clase.');
            return;
        }
        try {
            if (isEditing) {
                setLoading(true);
                // Edit existing attendance
                await axios.put(`${EDIT_ATTENDANCE_URL.replace('<int:user_id>', student.id).replace('<int:id>', editAttendanceId)}`, 

                    newAttendance, 
                    { headers });
                setIsEditing(false);
                setEditAttendanceId(null);
            } else {
                setLoading(true);
                // Add new attendance
                await axios.post(`${CREATE_ATTENDANCE_URL.replace('<int:user_id>', student.id)}`,
                     newAttendance,
                     { headers });
            }
            fetchAttendance();
            setShowAddAttendance(false);
            setNewAttendance({ class_name: localStorage.getItem('newAttendance') || '', observations: '' });
        } catch (error) {
            console.error('Error saving attendance:', error);
        }
        finally{
            setLoading(false);
        }
    };

    const handleEdit = (attendance) => {
        setNewAttendance({
            class_name: attendance.class_name,
            observations: attendance.observations,
        });
        setEditAttendanceId(attendance.id);
        setShowAddAttendance(true);
        setIsEditing(true);
    };

    const handleDelete = async (attendanceId) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar esta asistencia?')) 
            try {  
                setLoading(true);
                 await axios.delete(`${EDIT_ATTENDANCE_URL.replace('<int:user_id>', student.id).replace('<int:id>', attendanceId)}`, { headers });

                fetchAttendance();
            } catch (error) {
                console.error('Error deleting attendance:', error);
            }
            finally{
                setLoading(false);
            }
        };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <Modal show={show} onHide={handleClose}
            dialogClassName="custom-modal-width"

            className={`${theme === 'dark' ? 'modal-dark' : 'modal-light'} custom-modal`} // Clase personalizada
            >
            <Modal.Header closeButton
                            className={`custom-modal-header custom-modal-width ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}`} // Añadir clase
>
                <Modal.Title>Asistencia de {student?.first_name} {student?.last_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`custom-modal-width ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark  '}`}>

            <Button variant="outline-success" onClick={() => setShowAddAttendance(true)}

                disabled={loading}>
                {loading ? (
                        <Fragment>
                            Cargando <Spinner animation="border" size="sm" />
                        </Fragment>): 'Agregar Asistencia' }
                </Button>

                {showAddAttendance ? (
                    <>
                        {formError && <Alert variant="danger">{formError}</Alert>}
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Clase</Form.Label>
                                <Form.Select
                                    name="class_name"
                                    value={newAttendance.class_name}
                                    onChange={handleInputChange}
                                    className={`form-control  ${theme === "light" ? "" : "input-theme-black "}`}
                                    data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                                >
                                    <option value="">Seleccionar clase</option>
                                    {attendanceOptions.map((option, index) => (
                                        <option key={index} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Observaciones</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={6}
                                    name="observations"
                                    value={newAttendance.observations}
                                    onChange={handleInputChange}
                                    className={` ${theme === "light" ? "" : "input-theme-black "}`}
                                    data-bs-theme={`${theme === "light" ? "light" : "dark"}`}

                                />
                            </Form.Group>
                            <Button variant="primary" onClick={handleAddAttendance}
                                disabled={loading}>
                                
                                {loading ?  <Spinner animation="border" size="sm" /> : isEditing ? 'Guardar Cambios' : 'Guardar' }

                            </Button>
                            <Button variant="secondary" onClick={() => setShowAddAttendance(false)} className="ms-2">
                                Cancelar
                            </Button>
                        </Form>
                    </>
                ) : (
                    <>
                            <Table striped bordered hover variant={theme === 'dark' ? 'dark' : 'light'} className='mb-4'>
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Clase</th>
                                    <th>Observaciones</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading == false ? (attendances.length > 0 ? (attendances.map((attendance) => (
                                    <tr key={attendance.id}>
                                        <td>{new Date(attendance.date).toLocaleDateString()}</td>
                                        <td>{attendance.class_name}</td>
                                        <td>{attendance.observations}</td>
                                        <td className=''>
                                        <div className="d-flex justify-content-around">
                                            <Button variant="outline-primary" size="sm" onClick={() => handleEdit(attendance)}>
                                            <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                            <Button variant="outline-danger" size="sm" onClick={() => handleDelete(attendance.id)} className="ms-2">
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                            </Button>
                                        </div>
                                        </td>
                                    </tr>
                                ))) : 'Sin Información de asistencias') : 'Cargando...' }
                            </tbody>
                        </Table>
                        <Pagination>
                            {[...Array(totalPages).keys()].map((page) => (
                                <Pagination.Item
                                    key={page + 1}
                                    active={page + 1 === currentPage}
                                    onClick={() => handlePageChange(page + 1)}
                                >
                                    {page + 1}
                                </Pagination.Item>
                            ))}
                        </Pagination>
                        </>
                                                    )}
                                                </Modal.Body>
                                            </Modal>
                                    );
                                };

export default AttendanceModal;
