import React, { useState, useEffect, Fragment, useRef } from 'react';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Modal, Button, Form, Tabs, Tab, Alert, Table } from 'react-bootstrap';
import { PROSPECTS_URL, REMINDERS_URL, REMINDERS_TODAY_URL, SUMMARY_URL } from '../apiConfig';
import GestionCSV from './GestionCSV';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faCopy, faEyeSlash, faEye, faBellSlash, faBell   } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import ProspeccionModal from './ProspeccionModal'; 
import ClientModal from './ClientModal';
import Joyride from "react-joyride";


const Tablero = () => {
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Token ${token}` };
  const [columnas, setColumnas] = useState({
    'Prospectos': [],
    'Script 1': [],
    'Script 2': [],
    'Interesado': [],
    'Reunión Agendada': [],
    'Propuesta enviada': [],
    'Cliente Cerrado': [],
    'Cliente Escalado': []
  });

  const [filteredContacts, setFilteredContacts] = useState(columnas);
  const [prospStats, setprospStats] = useState({});
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());
  const [studentData, setStudentData] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem('filteredCountry') || '');
  const [selectedCity, setSelectedCity] = useState('');
  const [uniqueCities, setUniqueCities] = useState([]);
  const [filter, setFilter] = useState('todos'); // Filtro inicial: todos

  const [editMode, setEditMode] = useState({
    recordatorio : false,
    contact : false
  });
  const [archived, setArchived] = useState(false);
  const [copiedField, setCopiedField] = useState(null);
  const [contactoBlanco, setcontactoBlanco] = useState({
    nombre: '',
    apellido: '',
    estado: 'Prospectos',
    empresa: '',
    observations: '',
    pais: '',
    ciudad: '',
    contactado_por: 'Whatsapp',
    whatsapp: '',
    telefono: '',
    telefono_alternativo_1: '',
    telefono_alternativo_2: '',
    link_ig: '',
    link_fb: '',
    link_linkedin: '',
    link_tiktok: '',
    link_otro: '',
    link_otro_2: '',
    orden:0
  })

  const [nuevoContacto, setNuevoContacto] = useState(contactoBlanco);
  const [showModal, setShowModal] = useState(false);
  const [showModalImport, setShowModalImport] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [currentContacto, setCurrentContacto] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null); 
  const [showModalDisplay, setShowModalDisplay] = useState(false);
  const [showFilterModal, setshowFilterModal] = useState(false);
  const [mostrarOcultos, setMostrarOcultos] = useState(false); // Estado para manejar si se muestran los recordatorios ocultos o no

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [selectedOptions, setSelectedOptions] = useState(
    JSON.parse(localStorage.getItem('viewOptions')) || ['nombre', 'empresa']
  ); 
  const [reminders, setReminders] = useState([]);

  const [lastSelectedOptions, setLastSelectedOptions] = useState(null);
  const [recordatorios, setRecordatorios] = useState([]);
  const [recordatorioBase, setRecordatorioBase] = useState({
    fecha: new Date().toLocaleString(),
    descripcion: '',
    completado: false,
    user_created: true
  });
  const [nuevoRecordatorio, setNuevoRecordatorio] = useState(recordatorioBase)

  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState({});
  const [hiddenReminders, setHiddenReminders] = useState({});
  const [success, setSuccess] = useState('');
  const [addOrEdit, setAddOrEdit] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);

  const handleShowModal = () => setShowModal(true);
  // const handleShowModalImport = () => setShowModalImport(true);

  const handleCloseModal = () => {
    setShowModal(false);
    setEditMode((prev) => ({ ...prev, contact: false }))
    setNuevoContacto(contactoBlanco);
  };

  const handleCloseModalImport = () => {
    fetchContactData();
    setShowModalImport(false);
  };
  const handleShowDetailModal = (contacto) => {
    setCurrentContacto(contacto); 
    setSelectedContact(contacto.id);
    setShowDetailModal(true);
  };
  const handleCloseDetailModal = () => {
    setFilter('activos')
    setShowDetailModal(false);
    setNuevoRecordatorio(recordatorioBase)
    setAddOrEdit(false);
    setEditMode((prev) => ({ ...prev, recordatorio: false }))
    }

  useEffect(() => { //Fetch contacts
    document.body.className = theme; // Aplica la clase 'light' o 'dark' al body
    fetchContactData();
  }, []);

  useEffect(() => {
    const fetchReminders = async () => {
      try {
        const response = await axios.get(REMINDERS_TODAY_URL, { headers });
        setReminders(response.data);  // Suponiendo que la respuesta es un array de recordatorios
      } catch (error) {
        console.error('Error fetching reminders:', error);
      }
    };
    fetchReminders();
    cleanExpiredHiddenReminders(); // Limpiar recordatorios expirados al cargar

  }, [showDetailModal]);

      // Obtener los datos de prospección del alumno
  useEffect(() => {
    if (isModalOpen === true) {
      fetchStudentData();
    }
    else {
      fetchContactData();
    }
  }, [isModalOpen]);
  // // Detectar clic fuera del tablero para deseleccionar contacto
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (!event.target.closest('container') && !showDetailModal) {
  //       setSelectedContact(null);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);
  useEffect(() => {
    // Verifica si el tutorial ya fue mostrado
    const hasSeenTutorial = localStorage.getItem("hasSeenBoardTutorial");

    if (hasSeenTutorial !== "true") {
      setShowTutorial(true);
        localStorage.setItem("hasSeenBoardTutorial", "true");
    }
}, []);

const steps = [
  {
      target: "#recordatorios_div",
      content: (
        <>
          Aquí verás recordatorios para la prospección. Haz clic en el texto para abrir el prospecto correspondiente. Si haces clic en <FontAwesomeIcon icon={faEyeSlash} />, el recordatorio se ocultará por 1 hora y aparecerá el botón "Mostrar" para visualizarlos temporalmente. Para restablecerlo, haz clic en <FontAwesomeIcon icon={faEye} />. Para eliminarlo permanentemente, bórralo desde el prospecto.
        </>
      )
    },
  {
      target: "#estadisticas_div",
      content: "Aquí encontrarás un resumen de tus actividades de prospección. Nils necesitará estos datos para llevar un registro de tus avances. Se actualizan automáticamente.",
  },
  {
      target: "#prospeccion_button",
      content: "Aquí puedes agregar y/o modificar la información de tu prospección de forma manual. Esto es crucial para que Nils siga tu progreso. También se actualizan al mover el prospecto entre las columnas.",
  },
  {
      target: "#filtro_input",
      content: "Busca a tus prospectos por nombre o empresa. Si necesitas otros filtros, usa el buscador de tu navegador (Ctrl+F).",
  },
  {
      target: "#filtro_button",
      content: "Aquí podrás filtrar los prospectos por país y por ciudad (primero debes seleccionar el país). También podrás ver los prospectos que hayas archivado.",
  },
  {
      target: "#visualizacion_button",
      content: "Selecciona la información que deseas que aparezca en la tarjeta de cada prospecto. Puedes personalizarla según tus necesidades.",
  },
  {
      target: "#importar_button",
      content: "Aquí puedes agregar prospectos de forma manual",
  },
  {
      target: "#importar_csv_button",
      content: "Aquí puedes importar una lista de prospectos. Las instrucciones las encontrarás en Skool, en el video tutorial dentro del Módulo del Milki Challenge",
  },
  {
      target: "#scroll_buttons",
      content: "Con estos botones, puedes desplazarte horizontalmente por el tablero. También puedes hacerlo colocando el puntero del mouse sobre las columnas y girando la rueda del ratón mientras mantienes presionada la tecla Shift o Mayúsculas.",
  },
  {
      target: "#columns",
      content: "Aquí podrás mover los prospectos entre columnas a medida que los vayas contactando. La prospección se registrará automáticamente en los siguientes casos: Primera parte enviada hoy: Cuando pases un prospecto de la columna Prospectos a Script 1. Segunda parte enviada hoy: Cuando pases un prospecto de Script 1 a Script 2. Reuniones realizadas hoy: Cuando pases un prospecto a la columna Propuesta enviada.",  
    },
];

  const fetchContactData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${PROSPECTS_URL}`, { headers });
      setprospStats(response.data.prospeccion_hoy)
      const allowedColumns = ['Prospectos', 'Script 1', 'Script 2', 'Interesado', 'Reunión Agendada', 'Propuesta enviada', 'Cliente Cerrado', 'Cliente Escalado'];

      const contactosPorColumna = response.data.contactos.reduce(
        (acc, contacto) => {
          let estado = contacto.estado || 'Prospectos';
          if (!allowedColumns.includes(estado)) {
            estado = 'Prospectos';  // Asignar a 'Prospectos' si no es válido
          }

          if (!acc[estado]) acc[estado] = [];
          acc[estado].push(contacto);
          return acc;
        },
        {
          'Prospectos': [],
          'Script 1': [],
          'Script 2': [],
          'Interesado': [],
          'Reunión Agendada': [],
          'Propuesta enviada': [],
          'Cliente Cerrado': [],
          'Cliente Escalado': []
        }
      );
      setColumnas(contactosPorColumna);
      setFilteredContacts(contactosPorColumna);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized, removing token');
        localStorage.removeItem('token'); // Eliminar token si es un 401
        alert('Debes iniciar sesión nuevamente.');
        window.location.href = '/'; // Redirigir a /login

    } else {
        console.error('Error fetching student data:', error);
        alert('Ocurrió un error al cargar los contactos.');
    }
     
    } finally {
      setLoading(false);
    }
  };

  const onDragStart = async (result)=>
    {     
      const { draggableId } = result;
      const elemento = document.querySelector(`[data-rbd-draggable-id="${draggableId}"]`);
          // Agregar la clase
      elemento.classList.add('dragging');
    }

  const onDragEnd = async (result) => {
    let data = false;
    const { destination, source, draggableId } = result;
    const elemento = document.querySelector(`[data-rbd-draggable-id="${draggableId}"]`);

    elemento.classList.remove('dragging');

    if (!destination) return;
      // Obtén las columnas actuales
    const columnKeys = Object.keys(columnas);
    // Verifica si el destino es una de las columnas válidas
    if (!columnKeys.includes(destination.droppableId)) {
      console.error("Destino no válido: No es una de las columnas");
      return;
      }

    const contactoId = draggableId.split('-')[1];
    const updatedColumns = { ...columnas };

    const [movedContacto] = updatedColumns[source.droppableId].splice(source.index, 1);
    updatedColumns[destination.droppableId].splice(destination.index, 0, movedContacto);
    setLoading(true);
    setColumnas(updatedColumns);
    
    if (destination.droppableId === 'Propuesta enviada'){
      const confirmAddition = window.confirm("¿Deseas contar la reunión en la prospección de hoy?");
      if (confirmAddition) {
          data = true;
      }
    }
    console.log('data')

    console.log(data)
    try {
      // Realiza la solicitud PUT para actualizar el contacto en el servidor
      await axios.put(
        `${PROSPECTS_URL}${contactoId}/`,
        {
          estado: destination.droppableId,  // Columna de destino
          orden: destination.index,         // Índice de destino
          ultima_modificacion: new Date(Date.now()),
          data: {inc_reuniones: data},
        },
        { headers }
      );
        // Si la solicitud es exitosa, recarga los datos de contactos
      fetchContactData();
      if (destination.droppableId == 'Cliente Cerrado' || destination.droppableId == 'Cliente Escalado'){
        const confirmAddition = window.confirm("¿Deseas agregar los datos del cliente?");
        if (confirmAddition) {
            setIsClientModalOpen(true); // Abre el modal si el usuario confirma
        }
      }

    } catch (error) {
      // Manejo de errores
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized, removing token');
        localStorage.removeItem('token'); // Eliminar token si es un 401
        alert('Debes iniciar sesión nuevamente.');
        window.location.href = '/'; // Redirigir a /login
      } else {
        console.error('Error al actualizar el contacto:', error);
        alert('Ocurrió un error al cargar los contactos.');
      }
      
      
        setLoading(false);

      }
      finally{
        data = false
      };
  };
  


  const updateContact = (currentContacto) =>{
    setLoading(true);
    axios
      .put(
        `${PROSPECTS_URL}${currentContacto.id}/`,
        {
         nombre: currentContacto.nombre,
         apellido: currentContacto.apellido,
         empresa: currentContacto.empresa,
         telefono: currentContacto.telefono,
         pais: currentContacto.pais,
         ciudad: currentContacto.ciudad,
         contactado_por: currentContacto.contactado_por,
         whatsapp: currentContacto.whatsapp,
         observations: currentContacto.observations,
         archivado : currentContacto.archivado,
         email: currentContacto.email,
         email_2 : currentContacto.email_2,
         telefono_alternativo_1: currentContacto.telefono_alternativo_1,
         telefono_alternativo_2: currentContacto.telefono_alternativo_2,
         link_ig: currentContacto.link_ig,
         link_fb: currentContacto.link_fb,
         link_linkedin: currentContacto.link_linkedin,
         link_tiktok: currentContacto.link_tiktok,
         link_otro: currentContacto.link_otro,
         link_otro_2: currentContacto.link_otro_2,
         ultima_modificacion : new Date(Date.now())
        },
        { headers }
      )
      .then(() => {
        fetchContactData();
        setNuevoContacto(contactoBlanco);
        handleCloseModal()
        setShowDetailModal(false);
        setEditMode((prev) => ({ ...prev, contact: false }))

      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
            console.error('Unauthorized, removing token');
            localStorage.removeItem('token'); // Eliminar token si es un 401
            alert('Debes iniciar sesión nuevamente.');
            window.location.href = '/'; // Redirigir a /login
    
        } else {
        console.error('Error al actualizar el contacto:', error);
        alert('Ocurrió un error al actualizar el contacto.');
        setLoading(false);
      }
      });
  }

  const agregarContacto = () => {
    setLoading(true);
    nuevoContacto.orden = 0
    axios
      .post(PROSPECTS_URL, nuevoContacto, { headers })
      .then((response) => {
        const contacto = response.data;
        setColumnas((prevState) => ({
          ...prevState,
          'Prospectos': [...prevState['Prospectos'], contacto]
        }));
        setNuevoContacto(contactoBlanco);
        setLoading(false);
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
            console.error('Unauthorized, removing token');
            localStorage.removeItem('token'); // Eliminar token si es un 401
            alert('Debes iniciar sesión nuevamente.');
            window.location.href = '/'; // Redirigir a /login
    
        } else {
        setLoading(false);
        console.error('Error al agregar el contacto:', error);
        alert('Ocurrió un error al agregar el contacto.');
        }
      });
  };

  const eliminarContacto = (contactoId) => {
    setLoading(true);
    axios
      .delete(`${PROSPECTS_URL}${contactoId}/`, { headers })
      .then(() => {
        setColumnas((prevState) => {
          const updatedColumns = { ...prevState };
          Object.keys(updatedColumns).forEach((columnaId) => {
            updatedColumns[columnaId] = updatedColumns[columnaId].filter(
              (contacto) => contacto.id !== contactoId
            );
          });
          return updatedColumns;
        });
        setLoading(false);
        handleCloseDetailModal();
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
            console.error('Unauthorized, removing token');
            localStorage.removeItem('token'); // Eliminar token si es un 401
            alert('Debes iniciar sesión nuevamente.');
            window.location.href = '/'; // Redirigir a /login
    
        } else {
        setLoading(false);
        console.error('Error al eliminar el contacto:', error);
        alert('Ocurrió un error al eliminar el contacto.');
        }
      });
  };

  // Filtro por búsqueda
  useEffect(() => {
    const filtered = {};
    localStorage.setItem('filteredCountry', selectedCountry);
    Object.keys(columnas).forEach((key) => {
      filtered[key] = columnas[key].filter((contacto) =>
        archived ? contacto.archivado === true : contacto.archivado === false
      );
    });
  
    // Caso sin filtros
    if (searchTerm === '' && selectedCountry === '' && selectedCity === '') {
      setFilteredContacts(filtered);
    } 
    // Filtro solo por país
    else if (selectedCountry && !searchTerm && !selectedCity) {
      const countryFiltered = {};
      Object.keys(filtered).forEach((key) => {
        countryFiltered[key] = filtered[key].filter((contacto) =>
          contacto.pais?.toLowerCase() === selectedCountry.toLowerCase()
        );
      });
      setFilteredContacts(countryFiltered);
      setUniqueCities(Array.from(new Set(
        Object.values(countryFiltered)
      .flat()
      .map(contacto => capitalizeWords(contacto.ciudad))
      .filter(Boolean).sort((a, b) => a.localeCompare(b)) // Filtrar valores vacíos o indefinidos
      
    )))
    } 
    // Filtro solo por término de búsqueda
    else if (!selectedCountry && searchTerm && !selectedCity) {
      const searchFiltered = {};
      Object.keys(filtered).forEach((key) => {
        searchFiltered[key] = filtered[key].filter((contacto) =>
          contacto.nombre?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          contacto.empresa?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredContacts(searchFiltered);
    } 
    // Filtro por ciudad
    else if (selectedCity && !selectedCountry && !searchTerm) {
      const cityFiltered = {};
      Object.keys(filtered).forEach((key) => {
        cityFiltered[key] = filtered[key].filter((contacto) =>
          contacto.ciudad?.toLowerCase() === selectedCity.toLowerCase()
        );
      });
      setFilteredContacts(cityFiltered);
    }
    // Filtro por ciudad y país
    else if (selectedCity && selectedCountry && !searchTerm) {
      const cityAndCountryFiltered = {};
      Object.keys(filtered).forEach((key) => {
        cityAndCountryFiltered[key] = filtered[key].filter((contacto) =>
          contacto.ciudad?.toLowerCase() === selectedCity.toLowerCase() &&
          contacto.pais?.toLowerCase() === selectedCountry.toLowerCase()
        );
      });
      setFilteredContacts(cityAndCountryFiltered);
    }
    // Filtro por todos los términos
    else {
      const allFiltered = {};
      Object.keys(filtered).forEach((key) => {
        allFiltered[key] = filtered[key].filter((contacto) =>
          (contacto.nombre?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          contacto.empresa?.toLowerCase().includes(searchTerm.toLowerCase())) &&
          contacto.pais?.toLowerCase() === selectedCountry.toLowerCase() &&
          contacto.ciudad?.toLowerCase() === selectedCity.toLowerCase()
        );
      });
      setFilteredContacts(allFiltered);
    }
    
  }, [searchTerm, selectedCountry, selectedCity, columnas, archived]);
  

const capitalizeWords = (str) => {
  return str
    .toLowerCase() // Asegurarse de que el resto de la palabra esté en minúsculas
    .replace(/(^\w{1}|\s+\w{1})/g, char => char.toUpperCase());
};

  
const uniqueCountries = Array.from(new Set(
  Object.values(columnas)
    .flat()
    .filter(contacto => contacto && contacto.pais) // Filtrar valores vacíos
    .map(contacto => capitalizeWords(contacto.pais))
)).sort((a, b) => a.localeCompare(b)); // Ordenar alfabéticamente



  const handleEditMode = (data) => {
    // setEditMode(true)
    setEditMode((prev) => ({ ...prev, contact: true }))

    setNuevoContacto(data)
    handleCloseDetailModal()
    handleShowModal()
  };
  const totalElements = Object.values(filteredContacts).reduce((acc, list) => acc + list.length, 0);

  const formatWhatsAppNumber = (number) => {
    if (!number || number === null) {
      return ''; // Si el número no está definido, retorna una cadena vacía
    }
    // Remove spaces, hyphens, parentheses, and "+" to format for WhatsApp API
    return number.replace(/[\s()+-]/g, '');
  };
  
  const copyToClipboard = (field, value) => {
    navigator.clipboard.writeText(value);
    setCopiedField(field);
    setTimeout(() => setCopiedField(null), 2000); // Oculta el mensaje después de 2 segundos
  }

  const ContactInfo = ( numero ) => {
    return `https://web.whatsapp.com/send?phone=${formatWhatsAppNumber(numero)}`;
  }

  const handleSaveOptions = () => {
    if (selectedOptions.length < 1){
      alert('Debes seleccionar al menos uno')
      setSelectedOptions(lastSelectedOptions)
      setShowModalDisplay(false);

    }
    else {
    localStorage.setItem('viewOptions', JSON.stringify(selectedOptions));
    setLastSelectedOptions(selectedOptions)
    setShowModalDisplay(false);
  }

  };
  
  useEffect(() => {
    if (showDetailModal === true) {
      const fetchRecordatorios = async () => {
        try {
          setLoading(true);
          const response = await axios.get(`${REMINDERS_URL.replace('<int:pk>',currentContacto.id)}`, { headers });
          setRecordatorios(response.data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          if (err.status === 404)
            {     
              setError('No se encontraron recordatorios.');
              setTimeout(() => setError(null), 2000); // Oculta el mensaje después de 2 segundos
            }
          else {
            setError('Error al cargar los recordatorios.');
            setTimeout(() => setError(null), 2000); // Oculta el mensaje después de 2 segundos
          }
        }
      };
      fetchRecordatorios();
    }
  }, [showDetailModal]);

  // Agregar un nuevo recordatorio
 
  const manejarRecordatorio = async (recordatorio, accion) => {
    if (!recordatorio.descripcion) {
      setError('El recordatorio no puede estar vacío.');
      setTimeout(() => setError(null), 2000); // Oculta el mensaje después de 2 segundos
      return;
    }
  
    const fechaLocal = new Date(recordatorio.fecha);
    const fechaUTC = fechaLocal.toISOString();
    const requestData = {
      fecha: fechaUTC,
      descripcion: recordatorio.descripcion,
      contact: currentContacto.id,
      user_created: true,
      completado: recordatorio.completado || false,  // El estado completado puede ser actualizado
    };
  
    try {
      setLoading(true);
  
      if (accion === 'crear') {
        // Crear nuevo recordatorio
        const response = await axios.post(
          REMINDERS_URL.replace('<int:pk>', currentContacto.id),
          requestData,
          { headers }
        );
        setRecordatorios([...recordatorios, response.data]);
        setSuccess('Recordatorio agregado con éxito.');
      } else if (accion === 'actualizar') {
        // Actualizar recordatorio existente
        await axios.put(
          `${REMINDERS_URL.replace('<int:pk>', currentContacto.id)}${recordatorio.id}/`,
          requestData,
          { headers }
        );
        const updatedRecordatorios = recordatorios.map((rec) =>
          rec.id === recordatorio.id ? { ...rec, ...requestData } : rec
        );
        setRecordatorios(updatedRecordatorios);
        setSuccess('Recordatorio actualizado con éxito.');
      }
  
      setNuevoRecordatorio(recordatorioBase);  // Restablecer el estado del recordatorio
      setTimeout(() => setSuccess(null), 2000); // Oculta el mensaje después de 2 segundos
      setAddOrEdit(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setError(`Error al ${accion === 'crear' ? 'agregar' : 'actualizar'} el recordatorio.`);
      setTimeout(() => setError(null), 2000); // Oculta el mensaje después de 2 segundos
    } finally {
      setLoading(false);
    }
  };
  

  const eliminarRecordatorio = async (id) => {
    setError(null)
    setSuccess(null);
    
    try {
      setLoading(true);
      await axios.delete(`${REMINDERS_URL.replace('<int:pk>',currentContacto.id)}${id}/`, { headers });
      const updatedRecordatorios = recordatorios.filter(rec => rec.id !== id);
      setRecordatorios(updatedRecordatorios);
      setSuccess('Recordatorio eliminado con éxito.');
      setTimeout(() => setSuccess(null), 2000); // Oculta el mensaje después de 2 segundos

      setLoading(false);
      setEditMode((prev) => ({ ...prev, recordatorio: false }))

      // setEditMode(false)
    } catch (err) {
      setLoading(false);
      setError('Error al eliminar el recordatorio.');
      setTimeout(() => setError(null), 2000); // Oculta el mensaje después de 2 segundos
      
    }
  };
  const toggleEvent = () => {
    setAddOrEdit(prevState => !prevState);
    if (addOrEdit){
      setNuevoRecordatorio(recordatorioBase)
      setEditMode((prev) => ({ ...prev, recordatorio: false }))

    }
  };
  const contactMap = Object.values(columnas)
      .flat()
      .reduce((map, contact) => {
        map[contact.id] = contact;
        return map;
      }, {});

  // const handleHideReminder = (reminderId) => {
  //   // Filtramos los recordatorios para eliminar el que queremos ocultar
  //   const updatedReminders = reminders.filter(reminder => reminder.id !== reminderId);
  //   setReminders(updatedReminders);
  // };

  const filtrarRecordatorios = (rec) => {
    if (filter === 'todos') return true;
    if (filter === 'activos') return !rec.completado;
    if (filter === 'inactivos') return rec.completado;
    return true;
  };

  const handleHideReminder = (reminderId) => {
    const hiddenReminders = JSON.parse(localStorage.getItem('hiddenReminders')) || {};
    console.log(hiddenReminders)
    
    if (reminderId in hiddenReminders) {
      delete hiddenReminders[reminderId];
    } else {
      const expirationTime = new Date().getTime() + 3600000; // 1 hora (en milisegundos)
      // Guardar en localStorage con la marca de tiempo
      // const hiddenReminders = JSON.parse(localStorage.getItem('hiddenReminders')) || {};
      hiddenReminders[reminderId] = expirationTime;
    }
    localStorage.setItem('hiddenReminders', JSON.stringify(hiddenReminders));
    // Actualizar el estado
    setHiddenReminders(Object.keys(hiddenReminders));
  };

  const cleanExpiredHiddenReminders = () => {
    const currentTime = new Date().getTime();
    const hiddenReminders = JSON.parse(localStorage.getItem('hiddenReminders')) || {};
    // Filtrar los recordatorios que han expirado
    const updatedHiddenReminders = Object.fromEntries(
      Object.entries(hiddenReminders).filter(([id, expirationTime]) => expirationTime > currentTime)
    );
    
    localStorage.setItem('hiddenReminders', JSON.stringify(updatedHiddenReminders));
    
    // Actualizar el estado solo con recordatorios válidos
    setHiddenReminders(Object.keys(updatedHiddenReminders));
  };

  const toggleOcultos = () => {
    setMostrarOcultos(!mostrarOcultos); // Alternar entre mostrar o no los ocultos
  };

  const formatLink = (link, url= "https://www.linkedin.com/in") => {
    if (!link) return 'No disponible';
    
    // Verificar si es un nombre de usuario
    if (link.startsWith('@')) {
      return `${url}/${link.substring(1)}`;  // Redirigir a Instagram, por ejemplo
    }
  
    // Verificar si es una URL sin protocolo
    if (!link.startsWith('http://') && !link.startsWith('https://')) {
      return `https://${link}`;
    }
  
    return link;  // Retornar el link tal como está si ya tiene http o https
  };

  const columnsContainerRef = useRef(null);

  // Función para desplazar las columnas horizontalmente
  const scrollColumns = (scrollOffset) => {
    if (columnsContainerRef.current) {
      // Aquí agregamos más control al valor de scrollOffset
      const maxScrollLeft = columnsContainerRef.current.scrollWidth - columnsContainerRef.current.clientWidth;
      const newScrollLeft = columnsContainerRef.current.scrollLeft + scrollOffset;
      
      // Limitar el valor de scroll dentro de los límites del contenedor
      columnsContainerRef.current.scrollLeft = Math.max(0, Math.min(newScrollLeft, maxScrollLeft));
    }
  };

   // Cerrar el modal después de guardar los datos
   const handleModalClose = () => {
    setIsModalOpen(false);
    // fetchStudentData(); // Actualizar los datos después de guardar en el modal
};
const fetchStudentData = async () => {
  setLoading(true);
  try {
      const response = await axios.get(SUMMARY_URL, {
          headers,
          params: {
              start_date: startDate.toISOString(),//.split('T')[0],,
              end_date: endDate.toISOString()//.split('T')[0],
          },
      });
      setStudentData(response.data);
  } catch (error) {
      if (error.response && error.response.status === 401) {
          console.error('Unauthorized, removing token');
          localStorage.removeItem('token'); // Eliminar token si es un 401
          alert('Debes iniciar sesión nuevamente.');
          window.location.href = '/'; // Redirigir a /login
  
      } else {
      console.error('Error fetching student data:', error);
      }
  } finally {
    setLoading(false);
  }
};


  return (
    <>  
    <div className='container-fluid h-100'>
    <div> {/* Implementa Joyride para los pasos del tutorial */}
            
            <Joyride
                steps={steps}
                run={showTutorial} // Corre el tutorial si es la primera vez
                continuous
                showProgress
                showSkipButton
                styles={{
                                  options: { 
                                    zIndex: 100,
                                    primaryColor: "#ff001a", //theme === "light" ? "#808080" : "#f8f9fa", // Color de los botones principales
                                    backgroundColor: theme === "light" ? "#d4dde5" : "#343a40", // Fondo de los tooltips
                                    textColor: theme === "light" ? "#808080" : "#ffffff", // Color del texto
                                    arrowColor: theme === "light" ? "#d4dde5" : "#343a40", // Color de la flecha
                                  },
                                  tooltip: {
                                    borderRadius: '10px',
                                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                                  },
                                  buttonNext: {
                                    backgroundColor: '#007bff',
                                    borderRadius: '5px',
                
                                  },
                                  buttonBack: {
                                    color: theme === "light" ? "#808080" : "#ffffff",
                                    backgroundColor: "#757246",
                                    borderRadius: '5px',
                
                                  },
                                  buttonClose: {
                                    color: theme === "light" ? "#808080" : "#ffffff",
                                    width: "2rem",
                                    position: "absolute",
                                    top: "1px",    // Ajusta la posición superior
                                    right: "-7.5rem",  // Ajusta la posición derecha
                                    padding: "0px", // Reduce el padding para evitar desbordes
                                    fontSize: "1rem",
                                    background: "transparent",
                                    color: theme === "light" ? "#333" : "#fff",
                                  },
                                  
                                }}
                callback={(data) => {
                    if (data.status === "finished" || data.status === "skipped") {
                        setShowTutorial(false); // Cierra el tutorial cuando termine
                    }
                }}
            />
        </div>
    <div className="row h-100">
      <div  className='col-md-2 mt-5 ml-2 d-flex flex-column justify-content-start  '
        >
          <div id="recordatorios_div" style={{position:'sticky', top: 0}}>
        <h3 className='h3 mb-3 ml-0 p-2'>Recordatorios de hoy</h3>
        {reminders.length === 0 ? (
          <p>No hay recordatorios para hoy.</p>
        ) : (
          <table 
                  className={`table table-sm table-hover ${theme === "dark" ? "table-dark" : ''} `}>
            <thead>
              <tr className=''>
                <th className=' align-middle '>Descripción</th>
                <th>
                { hiddenReminders.length > 0 ? <button type="button" 
                        className="btn btn-primary btn-sm " 
                        data-toggle="button" 
                        aria-pressed="false" 
                        autoComplete="off"
                        title='Mostrar u ocultar recordatorios ocultos'
                        onClick={toggleOcultos} > 
                    {mostrarOcultos ? 'Ocultar' : 'Mostrar'}
                  </button> : ''}
                  </th>
              </tr>
            </thead>
            <tbody>
              {reminders.filter(filtrarRecordatorios) // Aplicar el filtro según activos/inactivos/todos
              // .filter(reminder => !hiddenReminders.includes(reminder.id.toString()))
              .filter(reminder => mostrarOcultos || !hiddenReminders.includes(reminder.id.toString())) // Mostrar u ocultar según el botón
              .map((reminder) => {
                const contacto = contactMap[reminder.contact];
                return contacto ? (
                  <tr key={reminder.id} 
                      style={{cursor:'pointer'}}>
                    <td onClick={() => handleShowDetailModal(contacto)} >{reminder.descripcion}</td>
                    <td className='align-middle text-center '>
                      <FontAwesomeIcon 
                        title='Ocultar por 1h'
                        className={`btn  ${theme === "dark" ? "text-light bg-dark" : ''} `} 
                        role="button" icon={hiddenReminders.includes(reminder.id.toString()) ? faEye : faEyeSlash}  
                        onClick={() => {handleHideReminder(reminder.id); 
                        }}/>
                    </td>
                  </tr>
                ) : null;
              })}
            </tbody>
          </table>
        )}
      </div>
      </div>
      <div className={`container col-md-8 mt-5 m-0 p-5 rounded  ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
        
          <div className="d-flex justify-content-center align-items-center my-4">
            <h1 className="mb-0">Tablero de Prospectos</h1>
          </div>
          <div className={`card p-4 ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
            <div id='estadisticas_div'
              className={`  d-flex flex-row justify-content-between mt-2  ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
              <span className={` d-flex flex-column justify-content-between align-items-center`}>
              <p> Primera parte enviada hoy:</p>
              <p> {prospStats.contactos_parte_1_enviada} </p>
              </span>
              <span className={` d-flex flex-column justify-content-between align-items-center`}>
              <p> Segunda parte enviada hoy:</p>
              <p> {prospStats.contactos_parte_2_enviada} </p>
              </span>
              <span className={` d-flex flex-column justify-content-between align-items-center`}>
              <p> Reuniones Realizadas hoy:</p>
              <p> {prospStats.reuniones_realizadas} </p>
              </span>
              <span className={` d-flex flex-column justify-content-between align-items-center`}>
              <p> Cantidad de prospectos:</p>
              <p> {totalElements} </p>
              </span>
            </div>
            <div>
            <button id='prospeccion_button'
                    className="btn btn-primary mt-4 " onClick={() => setIsModalOpen(true)}>Editar datos de Prospección</button>
                    {isModalOpen && <ProspeccionModal 
                    onClose={handleModalClose} 
                    startDate={startDate}   // 
                    endDate={endDate}
                    studentData={studentData}
                    setStudentData={setStudentData}    
                    backdrop="true"   
                    loading = {loading}
                    />} 
            </div>
          </div>
          <div className="d-flex p-4 toolbar justify-content-between align-items-center ">
            <input
              id='filtro_input'
              type="text"
              placeholder="Nombre o Empresa"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`form-control ms-4  ${theme === "light" ? "" : "input-theme-black"}`}
              data-bs-theme={`${theme === "light" ? "light" : "dark"}`}

            />
            <nav className={`navbar navbar-expand-lg ${theme === "light" ? "navbar-light bg-light" : "navbar-dark bg-dark"}`}>
              <div className="container-fluid ">
                <button className="navbar-toggler ms-4" type="button" data-bs-toggle="collapse" data-bs-target="#navbarButtons" aria-controls="navbarButtons" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarButtons"> 
                  <button 
                    id='filtro_button'
                    className={`btn btn-sm ${selectedCity === '' && selectedCountry === '' && !archived ? "btn-primary" :"btn btn-warning" } ms-4`} 
                    onClick={() => setshowFilterModal(true)}>
                    Filtros
                  </button>
              
                  <button 
                    id='visualizacion_button'
                    className="btn btn-sm btn-primary ms-4" 
                    onClick={() => setShowModalDisplay(true)}>
                    Visualización
                  </button>

                  <button 
                    id='importar_button'
                    variant="primary" 
                    onClick={handleShowModal}
                    className="btn btn-sm btn-outline-primary ms-4">
                    + Agregar Contacto
                  </button>
                  <button 
                    id='importar_csv_button'
                    className="btn btn-sm btn-outline-primary ms-4" 
                    onClick={() => setShowModalImport(true)}>Importar Contactos</button>
                    {showModalImport && <GestionCSV 
                    onClose={handleCloseModalImport} 
                    backdrop="true"   />}
                </div>    
              </div>
            </nav>
          </div>

          <Modal show={showModal} onHide={handleCloseModal} // MODAL DE AGREGAR/EDITAR
              dialogClassName="custom-modal-width"

              className={`${theme === 'dark' ? 'modal-dark' : 'modal-light'} custom-modal`} // Clase personalizada
              >
            <Modal.Header closeButton
              className={`custom-modal-header custom-modal-width ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}`} // Añadir clase
              >
              <Modal.Title>{editMode.contact === true? 'Editar Contacto' : 'Agregar Nuevo Contacto'} </Modal.Title>
            </Modal.Header>
            <Modal.Body className={`custom-modal-width ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark  '}`}>
              
              <Tabs defaultActiveKey="general" id="contacto-tabs" className=''>
                <Tab eventKey="general" title="Información General" className='mt-4 p-4'>
                  <Form>
                  <Form.Group controlId="formNombre">
                      <Form.Label>Nombre Completo</Form.Label>
                      <Form.Control
                        className={`form-control  ${theme === "light" ? "" : "input-theme-black "}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                        type="text"
                        placeholder="Ingresa el nombre"
                        value={nuevoContacto.nombre}
                        onChange={(e) => setNuevoContacto({ ...nuevoContacto, nombre: e.target.value })}
                      />
                    </Form.Group>
                    <Form.Group controlId="formEmpresa">
                      <Form.Label>Empresa</Form.Label>
                      <Form.Control
                        className={`form-control  ${theme === "light" ? "" : "input-theme-black "}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                        type="text"
                        placeholder="Ingresa la Empresa"
                        value={nuevoContacto.empresa}
                        onChange={(e) => setNuevoContacto({ ...nuevoContacto, empresa: e.target.value })}
                      />
                    </Form.Group>                  
                    <Form.Group controlId="formPais">
                      <Form.Label>País</Form.Label>
                      <Form.Control
                        className={`form-control w-50 ${theme === "light" ? "" : "input-theme-black "}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                        type="text"
                        placeholder="Ingresa el País"
                        value={nuevoContacto.pais}
                        onChange={(e) => setNuevoContacto({ ...nuevoContacto, pais: e.target.value })}
                      />
                    </Form.Group>
                    <Form.Group controlId="formCiudad">
                      <Form.Label>Ciudad</Form.Label>
                      <Form.Control
                        className={`form-control  ${theme === "light" ? "" : "input-theme-black "}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                        type="text"
                        placeholder="Ingresa la Ciudad"
                        value={nuevoContacto.ciudad}
                        onChange={(e) => setNuevoContacto({ ...nuevoContacto, ciudad: e.target.value })}
                      />
                    </Form.Group>
                    <Form.Group controlId="formContactado">
                      <Form.Label>Contactado por</Form.Label>
                      <Form.Control
                        as="select"
                        className={`form-control mb-4 ${theme === "light" ? "" : "input-theme-black "}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                        value={nuevoContacto.contactado_por}
                        onChange={(e) => {
                          setNuevoContacto({ ...nuevoContacto, contactado_por: e.target.value }); 
                        }}
                      >        
                        <option value="Whatsapp">Whatsapp</option>
                        <option value="Linkedin">Linkedin</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Instagram">Instagram</option>
                        <option value="Otro">Otro</option>
                        <option value="Volver a buscar">Volver a buscar</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formObservations">
                      <Form.Label>Observaciones</Form.Label>
                      <Form.Control
                        className={`form-control  ${theme === "light" ? "" : "input-theme-black "}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                        as="textarea"
                        rows={3}
                        placeholder="Ingresa alguna observación"
                        value={nuevoContacto.observations}
                        onChange={(e) => setNuevoContacto({ ...nuevoContacto, observations: e.target.value })}
                      />
                    </Form.Group>
                  </Form>

                </Tab>
                <Tab eventKey="telefonos" title="Teléfonos" className='mt-4 p-4'>
                      <Form.Group controlId="formWhatsapp" className=' w-50'>
                        <Form.Label>Whatsapp</Form.Label>
                        <Form.Control
                          className={`form-control  ${theme === "light" ? "" : "input-theme-black "}`}
                          data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                          type="text"
                          placeholder="Número de Whatsapp"
                          value={nuevoContacto.whatsapp}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Validar que solo contenga números y el símbolo '+'
                            const regex = /^\+?[0-9]*$/;
                      
                            // Limitar a 15 caracteres y aplicar la validación
                            if (regex.test(inputValue) && inputValue.length <= 15) {
                              setNuevoContacto({ ...nuevoContacto, whatsapp: e.target.value });
                            }
                          }}
                        />
                      </Form.Group>
                      <Form.Group controlId="formTelefono" className='w-50'>
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control
                        className={`form-control ${theme === "light" ? "" : "input-theme-black "}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                          type="text"
                          placeholder="Ingresa el teléfono"
                          value={nuevoContacto.telefono}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Validar que solo contenga números y el símbolo '+'
                            const regex = /^\+?[0-9]*$/;
                      
                            // Limitar a 15 caracteres y aplicar la validación
                            if (regex.test(inputValue) && inputValue.length <= 15) {
                              setNuevoContacto({ ...nuevoContacto, telefono: inputValue });
                            }
                          }}
                        />
                      </Form.Group>
                      <Form.Group controlId="formTelefonoAlt1" className='w-50'>
                        <Form.Label>Teléfono Alternativo 1</Form.Label>
                        <Form.Control
                        className={`form-control ${theme === "light" ? "" : "input-theme-black "}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                          type="text"
                          placeholder="Ingresa el teléfono"
                          value={nuevoContacto.telefono_alternativo_1}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Validar que solo contenga números y el símbolo '+'
                            const regex = /^\+?[0-9]*$/;
                      
                            // Limitar a 15 caracteres y aplicar la validación
                            if (regex.test(inputValue) && inputValue.length <= 15) {
                              setNuevoContacto({ ...nuevoContacto, telefono_alternativo_1: inputValue });
                            }
                          }}
                        />
                      </Form.Group>
                      <Form.Group controlId="formTelefonoAlt2" className='w-50'>
                        <Form.Label>Teléfono Alternativo 2</Form.Label>
                        <Form.Control
                        className={`form-control ${theme === "light" ? "" : "input-theme-black "}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                          type="text"
                          placeholder="Ingresa el teléfono"
                          value={nuevoContacto.telefono_alternativo_2}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Validar que solo contenga números y el símbolo '+'
                            const regex = /^\+?[0-9]*$/;
                              // Limitar a 15 caracteres y aplicar la validación
                            if (regex.test(inputValue) && inputValue.length <= 15) {
                              setNuevoContacto({ ...nuevoContacto, telefono_alternativo_2: inputValue });
                            }
                          }}
                        />
                      </Form.Group>

                </Tab>

                <Tab eventKey="links" title="Redes Sociales" className='mt-4 p-4'>
                  <Form.Group controlId="formEmail">
                    <Form.Label>Correo Electrónico</Form.Label>
                    <Form.Control
                      className={`form-control mb-4  ${emailError.email ? 'border-danger' : '' } ${theme === "light" ? "" : "input-theme-black "}`}
                      data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                      type="email"
                      placeholder="Ingresa el correo"
                      value={nuevoContacto.email}
                      onChange={(e) => {
                        const email = e.target.value;
                        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        setNuevoContacto({ ...nuevoContacto, email });
                        setEmailError((prev) => ({ ...prev, email: !regex.test(email) && email !== "" }));
                      }}
                    />
                    </Form.Group>

                  <Form.Group controlId="formEmail2">
                    <Form.Label>Correo Electrónico</Form.Label>
                    <Form.Control
                      className={`form-control mb-4  ${emailError.email_2 ? 'border-danger' : '' } ${theme === "light" ? "" : "input-theme-black "}`}
                      data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                      type="email"
                      placeholder="Ingresa el correo"
                      value={nuevoContacto.email_2}
                      onChange={(e) => {
                        const email_2 = e.target.value;
                        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        setNuevoContacto({ ...nuevoContacto, email_2 });
                        setEmailError((prev) => ({ ...prev, email_2: !regex.test(email_2) && email_2 !== "" }));
                      }}
                    />
                    </Form.Group>

                    <Form.Group controlId="formInstagram">
                      <Form.Label>Instagram</Form.Label>
                      <Form.Control
                        className={`form-control  ${theme === "light" ? "" : "input-theme-black "}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                        type="text"
                        placeholder="Ingresa el link"
                        value={nuevoContacto.link_ig}
                        onChange={(e) => setNuevoContacto({ ...nuevoContacto, link_ig: e.target.value })}
                      />
                    </Form.Group>   
                    <Form.Group controlId="formFacebook">
                      <Form.Label>Facebook</Form.Label>
                      <Form.Control
                        className={`form-control  ${theme === "light" ? "" : "input-theme-black "}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                        type="text"
                        placeholder="Ingresa el link"
                        value={nuevoContacto.link_fb}
                        onChange={(e) => setNuevoContacto({ ...nuevoContacto, link_fb: e.target.value })}
                      />
                    </Form.Group>    
                    <Form.Group controlId="formLinkedIn">
                      <Form.Label>LinkedIn</Form.Label>
                      <Form.Control
                        className={`form-control  ${theme === "light" ? "" : "input-theme-black "}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                        type="text"
                        placeholder="Ingresa el link"
                        value={nuevoContacto.link_linkedin}
                        onChange={(e) => setNuevoContacto({ ...nuevoContacto, link_linkedin: e.target.value })}
                      />
                    </Form.Group>
                    <Form.Group controlId="formLink1">
                      <Form.Label>Link</Form.Label>
                      <Form.Control
                        className={`form-control  ${theme === "light" ? "" : "input-theme-black "}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                        type="text"
                        placeholder="Ingresa el link"
                        value={nuevoContacto.link_otro}
                        onChange={(e) => setNuevoContacto({ ...nuevoContacto, link_otro: e.target.value })}
                      />
                    </Form.Group>
                    <Form.Group controlId="formLink2">
                      <Form.Label>Link</Form.Label>
                      <Form.Control
                        className={`form-control  ${theme === "light" ? "" : "input-theme-black "}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                        type="text"
                        placeholder="Ingresa el link"
                        value={nuevoContacto.link_otro_2}
                        onChange={(e) => setNuevoContacto({ ...nuevoContacto, link_otro_2: e.target.value })}
                      />
                    </Form.Group>


                </Tab>
              </Tabs>

            </Modal.Body>
            <Modal.Footer className={`custom-modal-width ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark  '}`}>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={editMode.contact === true? () => updateContact(nuevoContacto) : agregarContacto}
                    disabled={loading}>
                    {loading ? (
                    <Fragment>
                        Cargando <Spinner animation="border" size="sm" />
                    </Fragment>): editMode.contact === true? 'Guardar Cambios' :'Agregar Contacto'}
              </Button>
              </Modal.Footer>
          </Modal>

          <Modal show={showDetailModal} onHide={handleCloseDetailModal} // MODAL DE DETALLES
                  centered
                  dialogClassName="custom-modal-width"
                  >
            <Modal.Header closeButton 
              closeVariant={`${theme === 'dark' ? 'white' : 'black'}`} 
              className={`p-4 custom-modal-width ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}`}>
              <Modal.Title className=''>Detalles del Contacto</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`p-4 custom-modal-width ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}`}>
              {currentContacto && (
                <>
                  <Tabs defaultActiveKey="general" id="contacto-tabs" className=''>
                    {/* Pestaña para la Información General */}
                    <Tab eventKey="general" title="Información General" className='mt-4'>
                      <p>
                        <strong>Nombre:</strong> {currentContacto.nombre}
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('nombre', currentContacto.nombre)}
                        />
                        {copiedField === 'nombre' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                      </p>
                      <p>
                        <strong>Empresa:</strong> {currentContacto.empresa}
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('empresa', currentContacto.empresa)}
                        />
                        {copiedField === 'empresa' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                      </p>
                      <p>
                        <strong>País:</strong> {currentContacto.pais}
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('pais', currentContacto.pais)}
                        />
                        {copiedField === 'pais' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                      </p>
                      <p>
                        <strong>Ciudad:</strong> {currentContacto.ciudad}
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('ciudad', currentContacto.ciudad)}
                        />
                        {copiedField === 'ciudad' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                      </p>
                      <p>
                        <strong>Contactado por:</strong> {currentContacto.contactado_por}
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('contactado_por', currentContacto.contactado_por)}
                        />
                        {copiedField === 'contactado_por' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                      </p>
                      <p>
                        <strong>Observaciones:</strong> {currentContacto.observations}
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('observations', currentContacto.observations)}
                        />
                        {copiedField === 'observations' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                      </p>
                    </Tab>
                    {/* Pestaña para los Teléfonos */}
                    <Tab eventKey="telefonos" title="Teléfonos" className='mt-4'>
                      <p>
                        <strong>Whatsapp:</strong> {currentContacto.whatsapp}
                        <a href={ContactInfo(currentContacto.whatsapp)} target="_blank" rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faWhatsapp} style={{ color: "#25D366", marginLeft: "10px", cursor: "pointer" }} />
                        </a>
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('whatsapp', currentContacto.whatsapp)}
                          />
                        {copiedField === 'whatsapp' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                      </p>
                      <p>
                        <strong>Teléfono:</strong> {currentContacto.telefono}
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('telefono', currentContacto.telefono)}
                        />
                        {copiedField === 'telefono' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                        </p>
                      <p>
                        <strong>Teléfono Alternativo 1:</strong> {currentContacto.telefono_alternativo_1 || 'No disponible'}
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('telefono_alternativo_1', currentContacto.telefono_alternativo_1)}
                        />
                        {copiedField === 'telefono_alternativo_1' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                        </p>
                      <p>
                        <strong>Teléfono Alternativo 2:</strong> {currentContacto.telefono_alternativo_2 || 'No disponible'}
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('telefono_alternativo_2', currentContacto.telefono_alternativo_2)}
                        />
                        {copiedField === 'telefono_alternativo_2' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                      </p>
                    </Tab>

                    {/* Pestaña para los Links */}
                    <Tab eventKey="links" title="Redes Sociales" className='mt-4'>
                      <p>
                        <strong>Correo: </strong> 
                        <a href={`mailto:${currentContacto.email}`} target="_blank" rel="noopener noreferrer">{currentContacto.email || 'No disponible'}</a>
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('email', currentContacto.email)}
                        />
                        {copiedField === 'email' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                      </p>
                      <p>
                        <strong>Correo 2: </strong> 
                        <a href={`mailto:${currentContacto.email_2}`} target="_blank" rel="noopener noreferrer">{currentContacto.email_2 || 'No disponible'}</a>
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('email_2', currentContacto.email_2)}
                        />
                        {copiedField === 'email_2' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                      </p>
                      <p>
                        <strong>Instagram: </strong> 
                        <a href={formatLink(currentContacto.link_ig, "https://www.instagram.com")} target="_blank" rel="noopener noreferrer">{currentContacto.link_ig || 'No disponible'}</a>
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('link_ig', currentContacto.link_ig)}
                        />
                        {copiedField === 'link_ig' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                      </p>
                      <p>
                        <strong>Facebook: </strong>
                        <a href={formatLink(currentContacto.link_fb)} target="_blank" rel="noopener noreferrer">{currentContacto.link_fb || 'No disponible'}</a>
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('link_fb', currentContacto.link_fb)}
                        />
                        {copiedField === 'link_fb' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                      </p>
                      <p>
                        <strong>LinkedIn: </strong> 
                        <a href={formatLink(currentContacto.link_linkedin,"https://www.linkedin.com/in")} target="_blank" rel="noopener noreferrer">{currentContacto.link_linkedin || 'No disponible'}</a>
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('link_linkedin', currentContacto.link_linkedin)}
                        />
                        {copiedField === 'link_linkedin' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                      </p>
                      <p>
                        <strong>Link: </strong> 
                        <a href={formatLink(currentContacto.link_otro)} target="_blank" rel="noopener noreferrer">{currentContacto.link_otro || 'No disponible'}</a>
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('link_otro', currentContacto.link_otro)}
                        />
                        {copiedField === 'link_otro' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                      </p>
                      <p>
                        <strong>Link: </strong> 
                        <a href={formatLink(currentContacto.link_otro_2)} target="_blank" rel="noopener noreferrer">{currentContacto.link_otro_2 || 'No disponible'}</a>
                        <FontAwesomeIcon
                          icon={faCopy}
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          onClick={() => copyToClipboard('link_otro_2', currentContacto.link_otro_2)}
                        />
                        {copiedField === 'link_otro_2' && <span style={{ marginLeft: "10px", color: "green" }}>Copiado!</span>}
                      </p>
                    </Tab>
                    <Tab eventKey="alarms" title="Recordatorios" className='mt-4'>

                    <div className="mt-4">
                      {error && <Alert variant="danger">{error}</Alert>}
                      {success && <Alert variant="success">{success}</Alert>}
                      <Form.Group className="mb-3">
                        <div className='d-flex align-items-center'>
                          <Button variant={`${addOrEdit?"outline-primary":"primary"}`} className="" onClick={toggleEvent}>
                            {addOrEdit ? 'Ocultar' : 'Agregar Recordatorio'}
                          </Button>{/* Filtro para mostrar Todos, Activos o Inactivos */}
                         
                          {!addOrEdit ? (                          
                            <select
                              value={filter}
                              title='Filtrar recordatorios'
                              onChange={(e) => setFilter(e.target.value)}
                              className={`form-select ms-4 w-25 h-50 ${theme === "light" ? "" : "input-theme-black"}`}
                              data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                            >
                              <option value="todos">Todos</option>
                              <option value="activos">Activos</option>
                              <option value="inactivos">Inactivos</option>
                            </select>
                            ): ''}

                        </div>
                  {addOrEdit ? (
                    <div className='d-flex mt-3 mb-4'>
                      <Form.Control
                        type="date"
                        className={`form-control w-50 ${theme === "light" ? "" : "input-theme-black"}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                        value={nuevoRecordatorio.fecha.split("T")[0]}
                        disabled={loading}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const regex = /\d{4}-\d{2}-\d{2}/;
                          if (regex.test(inputValue) && inputValue.length <= 10) {
                            const updatedFecha = e.target.value;
                            const updatedHora = nuevoRecordatorio.fecha.split("T")[1] || "00:00:00";
                            setNuevoRecordatorio((prev) => ({
                              ...prev,
                              fecha: `${updatedFecha}T${updatedHora}`,
                            }));
                          }
                        }}
                      />

                      <Form.Control
                        type="time"
                        className={`form-control w-50 ms-3 ${theme === "light" ? "" : "input-theme-black"}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                        value={nuevoRecordatorio.fecha.split("T")[1]?.slice(0, 5)}
                        disabled={loading}
                        onChange={(e) => {
                          const updatedHora = e.target.value;
                          const updatedFecha = nuevoRecordatorio.fecha.split("T")[0] || new Date().toISOString().split("T")[0];
                          setNuevoRecordatorio((prev) => ({
                            ...prev,
                            fecha: `${updatedFecha}T${updatedHora}:00`,
                          }));
                        }}
                      />
                    </div>
                  ) : null}

                  {addOrEdit && (
                    <>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Escribe un recordatorio"
                        className={`mb-4 ${theme === "light" ? "" : "input-theme-black"}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                        value={nuevoRecordatorio.descripcion}
                        disabled={loading}
                        onChange={(e) =>
                          setNuevoRecordatorio((prev) => ({
                            ...prev,
                            descripcion: e.target.value,
                          }))
                        }
                      />
                      <Button className="mt-2" onClick={() => {manejarRecordatorio(nuevoRecordatorio, !editMode.recordatorio ? 'crear' : 'actualizar')}} disabled={loading}>
                        {loading ? <Spinner animation="border" size="sm" /> : !editMode.recordatorio ? 'Agregar Recordatorio' : 'Actualizar Recordatorio'}
                      </Button>
                    </>
                  )}
                  {!addOrEdit && (
                    recordatorios.length === 0 ? (
                      <p>No hay recordatorios asociados a este contacto.</p>
                    ) : (
                      <Table striped bordered hover className={`table w-100 ${theme === "light" ? "bg-light text-black" : "table-dark"}`}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Fecha</th>
                            <th>Descripción</th>
                            <th>Estado</th>
                            <th>Creado por</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {recordatorios
                            .filter(filtrarRecordatorios) // Aplicar el filtro
                            .map((rec, index) => (
                              <tr key={rec.id}>
                                <td>{index + 1}</td>
                                <td>{new Date(rec.fecha).toLocaleString()}</td>
                                <td>{rec.descripcion}</td>
                                <td>{rec.completado ? 'Inactiva' : 'Activa'}</td>
                                <td>{rec.user_created ? 'Manual' : 'Sistema'}</td>
                                <td>
                                  <div className='d-flex'>
                                    <button
                                      title={rec.completado ? 'Reactivar' : 'Inactivar'}
                                      className="btn btn-outline-primary me-2"
                                      onClick={() => {
                                        rec.completado = !rec.completado;
                                        setNuevoRecordatorio(rec);
                                        manejarRecordatorio(rec, 'actualizar');
                                      }}
                                      disabled={loading}
                                    >
                                      <FontAwesomeIcon icon={rec.completado ? faBell : faBellSlash } />
                                    </button>
                                    <button
                                      title="Editar"
                                      className="btn btn-outline-primary me-2"
                                      onClick={() => {
                                        setEditMode((prev) => ({ ...prev, recordatorio: true }));
                                        toggleEvent();
                                        setNuevoRecordatorio(rec);
                                      }}
                                      disabled={loading}
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <button
                                      title="Eliminar"
                                      className="btn btn-outline-danger"
                                      onClick={() => eliminarRecordatorio(rec.id)}
                                      disabled={loading}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    )
                  )}
                </Form.Group>
              </div>
                    </Tab>

                  </Tabs>
                </>
              )}
            </Modal.Body>
            <Modal.Footer className={`p-4 custom-modal-width ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}`}>
              <div className='d-flex flex-row m-auto'>
                <Button variant={`${currentContacto?.archivado ? "success" : "warning"}`} onClick={() => { currentContacto.archivado ? setCurrentContacto(currentContacto.archivado = false) : setCurrentContacto(currentContacto.archivado = true); updateContact(currentContacto); }} disabled={loading}>
                  {loading ? 'Cargando...' : currentContacto?.archivado ? 'Recuperar' : 'Archivar'}
                </Button>
                <Button variant="primary" onClick={() => handleEditMode(currentContacto)} className='ms-4' disabled={loading}>
                  {loading ? 'Cargando...' : 'Editar'}
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    if (window.confirm('¿Estás seguro de que deseas eliminar este contacto?')) {
                      eliminarContacto(currentContacto.id);
                    }
                  }}
                  className='ms-4'
                  disabled={loading}
>                  {loading ? 'Cargando...' : 'Eliminar'}
                </Button>
                <Button variant="secondary" onClick={handleCloseDetailModal} className='ms-4' disabled={loading}>
                  Cerrar
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          <Modal // MODAL DE VISUALIZACIÓN
            show={showModalDisplay} 
            onHide={() => setShowModalDisplay(false)}
            >
            <Modal.Header closeButton
              className={`modal-header  ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}`} // Añadir clase
  >
              <Modal.Title>Configurar Visualización</Modal.Title>
            </Modal.Header>
            <Modal.Body             
              className={`modal-body  ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}`} // Añadir clase
            >
              <Form>
                {['nombre', 'empresa', 'whatsapp', 'telefono', 'pais', 'ciudad', 'email_1','email_2','contactado_por', 'observaciones', 
                'telefono_alternativo_1', 'telefono_alternativo_2', 'Instagram', 'facebook', 'linkedin', 'tiktok'].map((option) => (
                  <Form.Check
                    key={option}
                    type="checkbox"
                    label={option.charAt(0).toUpperCase() + option.slice(1).replace(/_/g," ")}
                    checked={selectedOptions.includes(option)}
                    data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                    onChange={() => {
                      const newOptions = selectedOptions.includes(option)
                        ? selectedOptions.filter((opt) => opt !== option)
                        : [...selectedOptions, option];
                        setSelectedOptions(newOptions);
                    }}
                  />
                ))}
              </Form>
            </Modal.Body>
            <Modal.Footer
              className={`modal-footer  ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}`} // Añadir clase
  >
              <Button variant="secondary" onClick={() => {setSelectedOptions(lastSelectedOptions); setShowModalDisplay(false)}}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={handleSaveOptions}>
                Guardar
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal            //MODAL PARA FILTROS
            show={showFilterModal} 
            onHide={() => setshowFilterModal(false)}
            dialogClassName='modal-dialog-centered '
            >
            <Modal.Header closeButton
              className={`modal-header  ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}`} // Añadir clase
  >
              <Modal.Title>Filtros</Modal.Title>
            </Modal.Header>
            <Modal.Body             
              className={`modal-body  ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}`} // Añadir clase
            >
              <Form>
              <select value={selectedCountry} 
              onChange={(e) => {setSelectedCountry(e.target.value)
                setSelectedCity('')
                if (e.target.value === '') {
                  setUniqueCities(Array.from(new Set(
                    Object.values(columnas)
                  .flat()
                  .map(contacto => capitalizeWords(contacto.ciudad))
                  .filter(Boolean).sort((a, b) => a.localeCompare(b))  // Filtrar valores vacíos o indefinidos
                )))
                }
              }}
              className={`btn btn-secondary dropdown-toggle m-4 text-start w-75 ${theme === "light" ? "" : "input-theme-black"}`}
              data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
              >
              <option value="">Todos los países</option>
              {uniqueCountries.map((pais, index) => (
                <option key={index} value={pais}>
                  {pais}
                </option>
              ))}
            </select>
            <select value={selectedCity} 
              onChange={(e) => setSelectedCity(e.target.value)}
              className={`btn btn-secondary dropdown-toggle text-start mx-4 w-75 ${theme === "light" ? "" : "input-theme-black"}`}
              data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
              
              >
              <option value="">Ciudades</option>
              {uniqueCities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </select>
            <div className='d-flex '>
              <input type="checkbox" 
                id="toggleArchivado"
                className={`m-4  ${theme === "light" ? "" : "text-secondary"}`}
                data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                onChange={() => {setArchived(!archived);}}
                checked={archived === true}
                />  
              <label className="form-check-label my-4 " 
                for="toggleArchivado">Mostrar Archivados</label>
            </div>
              </Form>
            </Modal.Body>
            <Modal.Footer
              className={`modal-footer  ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}`} // Añadir clase
>
              <Button variant="primary" onClick={() => {
                setSelectedCountry("")
                setSelectedCity("")
                setArchived(false)
                setshowFilterModal(false)}}>
                Limpiar
              </Button>
              <Button variant="secondary" onClick={() => {setshowFilterModal(false)}}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Modal>
          {isClientModalOpen && (
                <ClientModal
                    show={isClientModalOpen}
                    onClose= {() => setIsClientModalOpen(false)} // Cierra el modal
                />
            )}
            
          <div className={` w-75 progress-bar-container m-auto rounded ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
          {loading ? <div className="progress-bar"></div>: ''
          }
          </div>  
          <div className='d-flex justify-content-between px-4' id='scroll_buttons'>
            <button className='btn btn-outline-success' onClick={() => scrollColumns(-100)}>Izquierda</button>
            <button className='btn btn-outline-success' onClick={() => scrollColumns(100)}>Derecha</button>
          </div>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <div className="tablero" id='columns' ref={columnsContainerRef}>
              
              {Object.keys(filteredContacts).map((columnaId) => {
                return (
              <Droppable droppableId={columnaId} key={columnaId}> 
                  {(provided) => (
                    <div
                      className={`ms-2 p-2 columna rounded ${theme === 'light' ? 'bg-light border border-secondary' : 'bg-secondary'}`}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <h2>{columnaId}</h2>
                      {filteredContacts[columnaId].map((contacto, index) => {
                        return (
                        <Draggable
                          key={`contacto-${contacto.id}`}
                          draggableId={`contacto-${contacto.id}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className={`contacto mb-4 p-3 rounded ${theme === 'light' ? 'bg-light border border-secondary ' : 'bg-dark'} ${selectedContact === contacto.id ? 'border border-primary border-3' : 'border border-secondary'}`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              onClick={() => handleShowDetailModal(contacto)}
                            >
                              <div className="contact-card">
                                {selectedOptions.includes('empresa') && (
                                  <span><strong>Empresa:</strong> {contacto.empresa}<br /></span>
                                )}
                                {selectedOptions.includes('nombre') && (
                                  <span><strong>Contacto:</strong> {contacto.nombre}<br /></span>
                                )}
                                {selectedOptions.includes('whatsapp') && (
                                  <span><strong>Whatsapp:</strong> {contacto.whatsapp}<br /></span>
                                )}
                                {selectedOptions.includes('telefono') && (
                                  <span><strong>Teléfono:</strong> {contacto.telefono}<br /></span>
                                )}
                                {selectedOptions.includes('pais') && (
                                  <span><strong>País:</strong> {contacto.pais}<br /></span>
                                )}
                                {selectedOptions.includes('ciudad') && (
                                  <span><strong>Ciudad:</strong> {contacto.ciudad}<br /></span>
                                )}
                                {selectedOptions.includes('contactado_por') && (
                                  <span><strong>Contactado por:</strong> {contacto.contactado_por}<br /></span>
                                )}
                                {selectedOptions.includes('observaciones') && (
                                  <span><strong>Observaciones:</strong> {contacto.observations}<br /></span>
                                )}
                                {selectedOptions.includes('telefono_alternativo_1') && (
                                  <span><strong>Teléfono alternativo 1:</strong> {contacto.telefono_alternativo_1}<br /></span>
                                )}
                                {selectedOptions.includes('telefono_alternativo_2') && (
                                  <span><strong>Teléfono alternativo 2:</strong> {contacto.telefono_alternativo_2}<br /></span>
                                )}
                                {selectedOptions.includes('Instagram') && (
                                  <span><strong>Instagram:</strong> {contacto.link_ig}<br /></span>
                                )}
                                {selectedOptions.includes('facebook') && (
                                  <span><strong>Facebook:</strong> {contacto.link_fb}<br /></span>
                                )}
                                {selectedOptions.includes('linkedin') && (
                                  <span><strong>LinkedIn:</strong> {contacto.link_linkedin}<br /></span>
                                )}
                                {selectedOptions.includes('tiktok') && (
                                  <span><strong>Tiktok:</strong> {contacto.link_tiktok}<br /></span>
                                )}
                                {selectedOptions.includes('email_1') && (
                                  <span><strong>Email 1:</strong> {contacto.email}<br /></span>
                                )}
                                {selectedOptions.includes('email_2') && (
                                  <span><strong>Email 2:</strong> {contacto.email_2}<br /></span>
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )})}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )})}
            </div>
          </DragDropContext>


      </div>
    </div>
  </div>

    </>
  );
};

export default Tablero;
