import React, { useEffect, useState } from 'react';
import ClientModal from './ClientModal';
import axios from 'axios';
import { RESUMEN_URL, SUMMARY_URL } from '../apiConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tab, Tabs } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import versionInfo from '../version.json'; // Importamos la información de la versión
import Joyride from "react-joyride";
import { right } from '@popperjs/core';

const Popup = ({ onClose, theme }) => (
  <div className={`popup-overlay `}>
    <div className={`popup-content w-50 d-flex flex-column justify-content-center p-4 ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
      <h2 className={`h2 ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>¡Bienvenido a la Milkiforma!</h2>
      <p className={`p p-2 ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
        Esta plataforma te ayudará en la prospección, ya que aquí podrás anotar diariamente lo que vayas haciendo. Nils necesitará algunos de estos datos para ayudarte y darte un seguimiento personalizado. Para empezar el tour guiado de está pantalla, dale clic al círculo rojo que está arriba este mensaje (el que tiene animación). </p>
        {/* <span style={{
          height: "1.5rem",
          width:"1.5rem",
          "display": "inline-block",
          "margin-right": "1rem",
          "margin-left": "1rem"

        }}>
        <span 
          style={{
          animation: "beacon-inner 1.2s ease-in-out infinite",
          "background-color": "rgb(255, 0, 26)",
          "border-radius": "50%",
          display: "block",
          height: "50%",    
          position: "relative",
          left: "1.3rem",
          top: "0.5rem",
          transform: "translate(-50%, -50%)",
          width: "50%",
          
        }}
        >
        </span>
        <span style={{
          animation: "beacon-outer 1.2s ease-in-out infinite",
          "background-color":" rgba(255, 0, 26, 0.2)",
          border: "2px solid rgb(255, 0, 26)",
          "border-radius": "50%",
          "box-sizing": "border-box",
          display: "block",
          height: "100%",
          left: "0.35",
          opacity: "0.9",
          position: "relative",
          bottom: "0.35",
          "transform-origin": "center",
          width: "100%"}}
        ></span> 
        </span> */}
          
      
      <button className='m-auto btn btn-primary mt-2' onClick={onClose}>Cerrar</button>
    </div>
  </div>
);

const Resumen = () => {
  const [streak, setStreak] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalClients, setTotalClients] = useState(0);
  const [script1ResponseRate, setScript1ResponseRate] = useState(0);
  const [script2ResponseRate, setScript2ResponseRate] = useState(0);
  const [closingRate, setClosingRate] = useState(0);
  const [closedClientRate, setclosedClientRate] = useState(0);
  const [followUpRate, setFollowUpRate] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fullName, setFullName] = useState('');
  const [lastLogin, setlastLogin] = useState('');
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado de error
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [version, setVersion] = useState('');
  const [showTutorial, setShowTutorial] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const hasSeenPopup = false;    // Simulación de variable

  
  // Función para obtener los datos del resumen
  const fetchUpdatedData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No autorizado');
        return;
      }

      const headers = { Authorization: `Token ${token}` };
      const response = await axios.get(SUMMARY_URL, { headers });
      const updatedData = response.data;

      // Actualiza el estado con los datos recibidos
      setStreak(updatedData.streak);
      setTotalPayment(updatedData.total_payment);
      setTotalClients(updatedData.total_clients);
      setScript1ResponseRate(updatedData.script1_response_rate);
      setScript2ResponseRate(updatedData.script2_response_rate);
      setClosingRate(updatedData.closing_rate);
      setclosedClientRate(updatedData.closed_clients_rate);
      setFollowUpRate(updatedData.follow_up_rate);
      setFullName(`${updatedData.first_name} ${updatedData.last_name}`);
      setlastLogin(`${updatedData.last_login}`);
    } catch (error) {
      if (error.response && error.response.status === 401) {
          console.error('Unauthorized, removing token');
          localStorage.removeItem('token'); // Eliminar token si es un 401
          alert('Debes iniciar sesión nuevamente.');
          window.location.href = '/'; // Redirigir a /login
  
      } else {
      setError('Error al obtener los datos del resumen.');
    }} finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.body.className = theme; // Aplica la clase 'light' o 'dark' al body
    setVersion(`${versionInfo.version}, build: ${versionInfo.buildDate})`);
    fetchUpdatedData(); // Llama a la función para obtener los datos una vez montado el componente
  }, []);

  useEffect(() => {
    // Verifica si el tutorial ya fue mostrado
    const hasSeenTutorial = localStorage.getItem("hasSeenResumenTutorial");

    if (hasSeenTutorial !== "true") {
        setShowTutorial(true);
        setShowPopup(true);
        localStorage.setItem("hasSeenResumenTutorial", "true");
    }
    
}, []);



  const handleClosePopup = () => {
      setShowPopup(false);
      // Aquí puedes actualizar la variable "hasSeenPopup" en localStorage o el backend
      localStorage.setItem('hasSeenPopup', true);
    };

  const steps = [
    {
        target: "#racha",
        content: "Este contador se incrementa cada día que envíes más de 10 Script 1, 10 Script 2 y hagas seguimiento a los prospectos",
    },
    {
        target: "#stats-tabs",
        content: "Aquí podrás ver algunas estadísticas de tu prospección",
    },
    {
        target: "#client_button",
        content: "Aquí podrás agregar y/o modificar la información de tus clientes",
    },
    {
        target: "#tutorial_button",
        content: "Con este botón podrás repetir el tutorial.",
    },
  ];

  const handleClientChange = () => {
    setIsModalOpen(false);
    fetchUpdatedData(); // Vuelve a cargar los datos después de gestionar clientes
  };


  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Cargando...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-5 text-danger">
        {error}
      </div>
    );
  }

  const chartData = (rate, labels = ['Respuestas', 'Sin Respuesta']) => ({
    labels: labels,
    datasets: [
      {
        data: [rate.toFixed(2), (100 - rate).toFixed(2)],
        backgroundColor: ['#36A2EB', '#FF6384'],
        hoverBackgroundColor: ['#0c66a3', '#d72349'],
        borderColor: ['#000000', '#000000'],      // Color de los bordes
        borderWidth: 2,                           // Grosor de los bordes
      },
    ],
  });
// Opciones de estilo para las gráficas
  const chartOptions = {
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            color: theme === "light" ? '#000' : '#fff',  // Color de la leyenda
            font: {
              size: 16,     // Tamaño de fuente de la leyenda
            },
          },
        },
      },
      layout: {
        padding: {
          left: 10,
          right: 10,
          top: 10,
          bottom: 10,
        },
      },
      responsive: true,
      maintainAspectRatio: false,  // Para ajustar el tamaño de la gráfica
    };


  return (
    <div className={`container mt-5 ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
    {showPopup && <Popup onClose={handleClosePopup} theme={theme} />}
      <div>
            {/* Implementa Joyride para los pasos del tutorial */}
            <Joyride
                steps={steps}
                run={showTutorial} // Corre el tutorial si es la primera vez
                continuous
                showProgress
                showSkipButton
                styles={{
                  options: { 
                    zIndex: 100,
                    primaryColor: "#ff001a", //theme === "light" ? "#808080" : "#f8f9fa", // Color de los botones principales
                    backgroundColor: theme === "light" ? "#d4dde5" : "#343a40", // Fondo de los tooltips
                    textColor: theme === "light" ? "#808080" : "#ffffff", // Color del texto
                    arrowColor: theme === "light" ? "#d4dde5" : "#343a40", // Color de la flecha
                  },
                  tooltip: {
                    borderRadius: '10px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                  },
                  buttonNext: {
                    backgroundColor: '#007bff',
                    borderRadius: '5px',

                  },
                  buttonBack: {
                    color: theme === "light" ? "#808080" : "#ffffff",
                    backgroundColor: "#757246",
                    borderRadius: '5px',

                  },
                  buttonClose: {
                    color: theme === "light" ? "#808080" : "#ffffff",
                    width: "2rem",
                    position: "absolute",
                    top: "1px",    // Ajusta la posición superior
                    right: "-7.5rem",  // Ajusta la posición derecha
                    padding: "0px", // Reduce el padding para evitar desbordes
                    fontSize: "1rem",
                    background: "transparent",
                    color: theme === "light" ? "#333" : "#fff",
                  },
                  
                }}
                callback={(data) => {
                    if (data.status === "finished" || data.status === "skipped") {
                        setShowTutorial(false); // Cierra el tutorial cuando termine
                    }
                }}
            />
        </div>
      <div className="text-center mb-4 pt-4">
        <h1>Salud de mi negocio</h1>
      </div>
      <div className={`card p-4 ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
        <h2 className="h2 resumen-nombre" id="racha"
          title="Días que envías más de 10 Script 1, 10 Script 2 y realizas seguimiento">
          Bienvenido {fullName}, llevas {streak} {streak === 1 ? 'día' : 'días'} de racha{streak >= 5 ? '!!!' : '.'}
        </h2>
        {/* <p className='p-4'> Última conexión: {lastLogin.toString().split('.')[0].replace('T', ' ')}</p> */}
        {/* <div className={`card p-4 ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
          <h2 className="h3 mt-4">Estadísticas de prospección</h2>
          <p>Respuestas a script 1: {script1ResponseRate ? parseFloat(script1ResponseRate).toFixed(2) : '0.00'}%</p>
          <p>Respuestas a script 2: {script2ResponseRate ? parseFloat(script2ResponseRate).toFixed(2) : '0.00'}%</p>
          <p>Tasa de cierre (Clientes / Contactados): {closingRate ? parseFloat(closingRate).toFixed(2) : '0.00'}%</p>
          <p>Tasa de cierre (Clientes / Reuniones): {closedClientRate ? parseFloat(closedClientRate).toFixed(2) : '0.00'}%</p>
          <p>Tasa de seguimiento: {followUpRate ? parseFloat(followUpRate).toFixed(2) : '0.00'}%</p>
        </div> */}
        <Tabs defaultActiveKey="script1" id="stats-tabs" className={`mb-3 main-tab  ${theme === 'dark' ? 'dark-mode' : 'light-mode'}`}
        
          >
      
          {/* Gráfica Script 1 */}
          <Tab eventKey="script1" title="Respuestas Script 1" className={`graphs m-4 justify-content-center  `}>
            <Pie data={chartData(script1ResponseRate, ['Respondido', 'Sin Respuesta'])} options={chartOptions} />
          </Tab>

          {/* Gráfica Script 2 */}
          <Tab eventKey="script2" title="Respuestas Script 2" className='graphs m-4 '>
            <Pie data={chartData(script2ResponseRate, ['Respondido', 'Sin Respuesta'])} options={chartOptions}/>
          </Tab>

          {/* Gráfica Tasa de cierre */}
          <Tab eventKey="closingRate" title="Tasa de Cierre" className='graphs mt-4 '>
            <h4 className='h4 mt-5'>Tasa de cierre: Cantidad de clientes entre Total Script 1 enviado</h4>
            <h1 className='h1'>{closingRate ? parseFloat(closingRate).toFixed(2) : '0.00'}%</h1>
            <h4 className='h4'>Tasa de cierre: Cantidad de clientes entre Total de reuniones</h4>
            <h1 className='h1'>{closedClientRate ? parseFloat(closedClientRate).toFixed(2) : '0.00'}%</h1>
            <h4 className='h4'>Tasa de seguimiento: </h4>
            <h1 className='h1'>{followUpRate ? parseFloat(followUpRate).toFixed(2) : '0.00'}%</h1>

          </Tab>

        </Tabs>
          <div className={`card p-4 ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
        <h2 className="h3 mt-4">Resumen de facturación</h2>
          <p className="mb-2">Total de pagos mensuales: ${totalPayment}</p>
          <p>Total de clientes: {totalClients}</p>
          <button className="btn btn-primary mt-3" id='client_button' onClick={() => setIsModalOpen(true)}>
            Gestionar Clientes
          </button>
          {isModalOpen && <ClientModal onClose={handleClientChange}   
          />}
        </div>

      </div>
      <div>
        <p>Versión: {version}</p> {/* Mostrar la versión aquí */}      </div>
    </div>
    
  );
};

export default Resumen;
