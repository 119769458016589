import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import LoginPage from './pages/LoginPage';
import Resumen from './components/Resumen';
import Prospeccion from './components/Prospeccion';
import Personal from './components/Personal';
import './App.css';
import { SummaryProvider } from './components/SummaryContext';
import SupervisorResumen from './components/SupervisorResumen'; // Nuevo componente para supervisores
import UserManagement from './components/UserManagement';
import SupervisorNavbar from './components/SupervisorNavbar'; // Nuevo Navbar para supervisores
import Profile from './components/Profile'; // Nuevo Navbar para supervisores
import Tablero from './components/ClientBoard'; // Nuevo Navbar para supervisores
import AttendanceCalendar from './components/AttendanceCalendar'; // Nuevo Navbar para supervisores
import ErrorBoundary from './components/ErrorBoundary';
import NotFoundPage from './components/NotFoundPage'; // Ajusta la ruta según tu estructura de archivos

function App() {
    return (
        <Router>
            <SummaryProvider>
                <ErrorBoundary>
                    <AppRoutes />
                </ErrorBoundary>
            </SummaryProvider>
        </Router>
    );
}

function AppRoutes() {
    const location = useLocation();
    // localStorage.removeItem('token');
    const token = localStorage.getItem('token');
    const [isSupervisor, setIsSupervisor] = useState(false);
    useEffect(() => {
        const checkSupervisorStatus = () => {
            const supervisorStatus = localStorage.getItem('isSupervisor') === "true";
            setIsSupervisor(supervisorStatus);
        };

        checkSupervisorStatus();
    }, [localStorage.getItem('isSupervisor')]);
    
    const isAuthenticated = !!token;
    return (
        <>
            {/* Navbar de Estudiantes */}
            {!isSupervisor && location.pathname !== '/' && <Navbar />}
            {/* Navbar de Supervisores */}
            {isSupervisor && location.pathname.startsWith('/supervision') && <SupervisorNavbar />}

            <Routes>
                <Route path="/" element={isAuthenticated ? <Navigate to={isSupervisor ? "/supervision/resumen" : "/resumen"} /> : <LoginPage />} />
                
                {/* Rutas para supervisores */}
                {isSupervisor &&  (
                    <>
                        <Route path="/supervision/resumen" element={<SupervisorResumen />} />
                        <Route path="/supervision/user-management" element={isSupervisor ? <UserManagement/> : <Navigate to="/"/> } />
                        <Route path="/supervision/attendance" element={<AttendanceCalendar />} />
                        <Route path="*" element={<Navigate to="/supervision/resumen" />} />
                    </>
                )}

                {/* Rutas para estudiantes */}
                {!isSupervisor && (
                    <>
                        <Route path="/resumen" element={isAuthenticated ? <Resumen /> : <Navigate to="/" />} />
                        <Route path="/prospeccion" element={isAuthenticated ? <Prospeccion /> : <Navigate to="/" />} />
                        <Route path="/personal" element={isAuthenticated ? <Personal /> : <Navigate to="/" />} />
                        <Route path="/profile" element={isAuthenticated ? <Profile /> : <Navigate to="/" />} />
                        <Route path="/board" element={isAuthenticated ? <Tablero /> : <Navigate to="/" />} />
                        <Route path="*" element={<Navigate to="/resumen" />} />
                    </>
                )}
            </Routes>
        </>
    );
}

export default App;
