import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PROSPECTION_URL, SUMMARY_URL } from '../apiConfig';
import './Prospeccion.css'; 
import ProspeccionModal from './ProspeccionModal'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import Joyride from "react-joyride";

const Prospeccion = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [saveLoading, setSaveLoading] = useState(false);
    const [studentData, setStudentData] = useState([]);
    const [studentData2, setStudentData2] = useState([]);

    const [startDate, setStartDate] = useState(() => {
        const date = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000); // 7 días atrás
        date.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00
        return date;
    });
    const [endDate, setEndDate] = useState(() => {
        const date = new Date();
        date.setHours(23, 59, 59, 999); // Establecer la hora a 23:59:59
        return date;
    });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const token = localStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
    const [showTutorial, setShowTutorial] = useState(false);

    // Obtener los datos de prospección del alumno
    useEffect(() => {
        document.body.className = theme; // Aplica la clase 'light' o 'dark' al body

        fetchStudentData();
    }, []);

    useEffect(() => {
        // Verifica si el tutorial ya fue mostrado
        const hasSeenTutorial = localStorage.getItem("hasSeenResumenTutorial");
    
        if (hasSeenTutorial !== "true") {
            setShowTutorial(true);
            localStorage.setItem("hasSeenProspeccionTutorial", "true");
        }
    }, []);

    const steps = [
        {
            target: "#prospection_button",
            content: "Aquí puedes agregar o modificar la información de tu prospección. Nils necesitará estos datos para llevar un registro de tus avances. Puedes completarla manualmente aquí o directamente en el Tablero.",
        },
        {
            target: "#date_filter",
            content: "Aquí puedes filtrar los datos de prospección según la fecha que necesites.",
        },
        {
            target: "#prospection_table",
            content: "Aquí se mostrarán los datos de tu prospección. Tal como los ves aquí, Nils podrá visualizarlos durante las clases en vivo.",
        },
      ];

    const fetchStudentData = async (
        start_date2 = (() => {
          const date = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000); // 7 días atrás
          date.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00
          return date;
        })(),
        end_date2 = (() => {
          const date = new Date();
          date.setHours(23, 59, 59, 999); // Establecer la hora a 23:59:59
          return date;
        })()
      ) => {
        setSaveLoading(true);
        try {
            const response = await axios.get(SUMMARY_URL, {
                headers,
                params: {
                    start_date: start_date2.toISOString(),//.split('T')[0],,
                    end_date: end_date2.toISOString()//.split('T')[0],
                },
            });
            setStudentData(response.data);
            const prospection2 = response.data;
            console.log(response.data)
            const combinedData = combineDataByDate(prospection2.prospection,start_date2, end_date2);
            setStudentData2(combinedData);

        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized, removing token');
                localStorage.removeItem('token'); // Eliminar token si es un 401
                alert('Debes iniciar sesión nuevamente.');
                window.location.href = '/'; // Redirigir a /login
            } else {
            console.error('Error fetching student data:', error);
            }
        } finally {
            setSaveLoading(false);
        }
    };

    // Filtrar los datos por el rango de fechas seleccionado
    const handleFilterClick = () => {
        fetchStudentData(startDate, endDate);
    };

    // Obtener los datos de la última semana
    const handleFetchLastWeek = () => {
        const newStartDate = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
        newStartDate.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00

        const newEndDate = new Date();
        newEndDate.setHours(23, 59, 59, 999); // Establecer la hora a 23:59:59

        fetchStudentData(newStartDate, newEndDate)
    };

    // Obtener los datos del último mes
    const handleFetchLastMonth = () => {
        const newStartDate = new Date(Date.now() - 29 * 24 * 60 * 60 * 1000);
        newStartDate.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00

        const newEndDate = new Date();
        newEndDate.setHours(23, 59, 59, 999); // Establecer la hora a 23:59:59

        fetchStudentData(newStartDate, newEndDate)
    };

    // Calcular las sumatorias de las columnas
    const sumColumn = (key) => {
        return studentData.prospection.reduce((sum, row) => sum + (parseInt(row[key], 10) || 0), 0);
    };

    // Cerrar el modal después de guardar los datos
    const handleModalClose = () => {
        setIsModalOpen(false);
        // fetchStudentData(); // Actualizar los datos después de guardar en el modal
    };
    const combineDataByDate = (prospection, startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const dateMap = new Map();
    
        prospection.forEach((item) => {
            // Extraer solo la parte de la fecha (sin la hora)
            const dateStr = item.date.split('T')[0];
            console.log(dateStr)
            dateMap.set(dateStr, {
                fecha: dateStr,
                contactos_parte_1_enviada: item.contactos_parte_1_enviada || 0,
                contactos_parte_2_enviada: item.contactos_parte_2_enviada || 0,
                contactos_parte_2_respondida: item.contactos_parte_2_respondida || 0,

                reuniones_realizadas: item.reuniones_realizadas || 0,
            });
        });
        const result = [];
        for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            const dateStr = dt.toISOString().split('T')[0]; // Formatear la fecha sin la hora
            if (!dateMap.has(dateStr)) {
                dateMap.set(dateStr, {
                    fecha: dateStr,
                    contactos_parte_1_enviada: 0,
                    contactos_parte_2_enviada: 0,
                    contactos_parte_2_respondida: 0,
                    reuniones_realizadas: 0,
                });
            }
            result.push(dateMap.get(dateStr));
        }
    
        // Ordenar las fechas de manera descendente
        return result.sort((b, a) => new Date(a.fecha) - new Date(b.fecha));
    };

    if (saveLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        // <div className="prospection-container">
        <div className={`container mt-5 p-5 ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
            <div>
            {/* Implementa Joyride para los pasos del tutorial */}
            <Joyride
                steps={steps}
                run={showTutorial} // Corre el tutorial si es la primera vez
                continuous
                showProgress
                showSkipButton
                styles={{
                                  options: { 
                                    zIndex: 100,
                                    primaryColor: "#ff001a", //theme === "light" ? "#808080" : "#f8f9fa", // Color de los botones principales
                                    backgroundColor: theme === "light" ? "#d4dde5" : "#343a40", // Fondo de los tooltips
                                    textColor: theme === "light" ? "#808080" : "#ffffff", // Color del texto
                                    arrowColor: theme === "light" ? "#d4dde5" : "#343a40", // Color de la flecha
                                  },
                                  tooltip: {
                                    borderRadius: '10px',
                                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                                  },
                                  buttonNext: {
                                    backgroundColor: '#007bff',
                                    borderRadius: '5px',
                
                                  },
                                  buttonBack: {
                                    color: theme === "light" ? "#808080" : "#ffffff",
                                    backgroundColor: "#757246",
                                    borderRadius: '5px',
                
                                  },
                                  buttonClose: {
                                    color: theme === "light" ? "#808080" : "#ffffff",
                                    width: "2rem",
                                    position: "absolute",
                                    top: "1px",    // Ajusta la posición superior
                                    right: "-7.5rem",  // Ajusta la posición derecha
                                    padding: "0px", // Reduce el padding para evitar desbordes
                                    fontSize: "1rem",
                                    background: "transparent",
                                    color: theme === "light" ? "#333" : "#fff",
                                  },
                                  
                                }}
                callback={(data) => {
                    if (data.status === "finished" || data.status === "skipped") {
                        setShowTutorial(false); // Cierra el tutorial cuando termine
                    }
                }}
            />
            </div>
            <div className='main_div'>
                <h1>Prospección</h1>
                <div className='date-container d-flex justify-content-center m-2'>
                    <button className="btn btn-primary mt-4 "
                    id='prospection_button'
                    
                    onClick={() => setIsModalOpen(true)}>Agregar o modificar datos</button>
                    {isModalOpen && <ProspeccionModal 
                    onClose={handleModalClose} 
                    startDate={startDate}   // 
                    endDate={endDate}
                    studentData={studentData}
                    setStudentData={setStudentData}    
                    backdrop="true"   
                    saveLoading = {saveLoading}
                    />} 
                </div>
                <div className='m-4 d-flex justify-content-between' id='date_filter'>
                    <div className='d-flex align-items-end'>
                        <div className="" >
                            <label>Fecha Inicio:</label>
                            <input
                                type="date"
                                maxDate={new Date()}
                                value={startDate.toISOString().split('T')[0]}
                                className={`form-control ${theme === "light" ? "" : "input-theme-black"}`}
                                data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const regex = /\d{4}-\d{2}-\d{2}/
                                    if (regex.test(inputValue) && inputValue.length <= 10) {
                                        const selectedDate = new Date(`${inputValue}T00:00:00`); // Mantén la hora en UTC (medianoche)
                                        selectedDate.setUTCHours(23, 59, 59, 999); // Ajusta la hora a  UTC
                                        setStartDate(selectedDate);
                                    }
                                }}
                            />
                        </div>
                        <div className="ms-4 ">
                            <label>Fecha Fin:</label>
                            <input
                                type="date"
                                maxDate={new Date()}
                                className={`form-control ${theme === "light" ? "" : "input-theme-black"}`}
                                data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                                value={endDate.toISOString().split('T')[0]}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const regex = /\d{4}-\d{2}-\d{2}/
                                    if (regex.test(inputValue) && inputValue.length <= 10) {         
                                        const selectedDate = new Date(`${inputValue}T00:00:00`); // Mantén la hora en UTC (medianoche)
                                        selectedDate.setUTCHours(23, 59, 59, 999); // Ajusta la hora a 23:59:59 UTC
                                        setEndDate(selectedDate);
                                    }
                                }}
                            />
                        </div>
                        <div className="ms-4">
                            <button onClick={handleFilterClick} className="btn btn-primary m-0">Filtrar</button>
                        </div>
                    </div>
                
                    <div className='d-flex align-items-end'>
                        <div className="">
                            <button onClick={handleFetchLastWeek} className="btn btn-secondary m-0">Última Semana</button>
                        </div>
                        <div 
                            className="ms-4">
                            <button onClick={handleFetchLastMonth} className="btn btn-secondary m-0">Último Mes</button>
                        </div>
                    </div>
                </div>
                {/* {studentData.prospection && studentData.prospection.length > 0 ? ( */}
                    <table id="prospection_table"
                        className={`table table-hover ${theme === "light" ? "bg-light text-black" : "table-dark"}`}>
                        <thead className={`${theme === "light" ? "table-success" : "thead-dark"}`}>
                            <tr>
                                <th>Fecha</th>
                                <th>1ra Parte Enviada</th>
                                <th>2da Parte Enviada</th>
                                <th>2da Parte Respondida</th>
                                <th>Reuniones Realizadas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentData.prospection && studentData.prospection.length > 0 ? (

                            <tr className={`table-secondary ${theme === "light" ? "" : ""}`}>
                                <td>Total</td>
                                <td>{sumColumn('contactos_parte_1_enviada')}</td>
                                <td>{sumColumn('contactos_parte_2_enviada')}</td>
                                <td>{sumColumn('contactos_parte_2_respondida')}</td>
                                <td>{sumColumn('reuniones_realizadas')}</td>
                            </tr> ) : (<tr className={`table-secondary ${theme === "light" ? "" : ""}`}>
                                <td>Total</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                                <td>0</td>
                            </tr>) }
                            {studentData.prospection && studentData.prospection.length > 0 ? ( studentData2.map((data, index) => (
                                
                                <tr key={index}>
                                    {/* <td>{new Date(data.date).toLocaleDateString()}</td> */}
                                    <td>{data.fecha}</td>
                                    
                                    <td>{data.contactos_parte_1_enviada}</td>
                                    <td>{data.contactos_parte_2_enviada}</td>
                                    <td>{data.contactos_parte_2_respondida}</td>
                                    <td>{data.reuniones_realizadas}</td>
                                </tr>
                            ))) :  (<tr>
                                    {/* <td>{new Date(data.date).toLocaleDateString()}</td> */}
                                    <td>0</td>
                                    
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                </tr>) }
                            
                        </tbody>
                    </table>
                 {/* : (
                    <p className="text-danger mt-3">No se encontraron datos para el rango de fechas seleccionado.</p>
                ) */}
                
            </div>
        </div>
    );
};

export default Prospeccion;
