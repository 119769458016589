import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PROFILE_URL, UPDATE_PROFILE_URL } from '../apiConfig';
import { TailSpin } from 'react-loader-spinner';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment-timezone';
import countriesData from './countries.json'; 
import nichesData from './niches.json'; 

import Select from 'react-select';

const Profile = () => {
  const countries = countriesData.countries;
  const niches = nichesData.niches;

  const [selectedNiche, setSelectedNiche] = useState(null);
  const [customNiche, setCustomNiche] = useState('');

  const [loading, setLoading] = useState(false);
  const [studentDetails, setStudentDetails] = useState({
    niche: '',
    prosp_country: '',
    script: '',
    service_offer: '',
    observations: '',
    timezone: 'UTC'  // Valor predeterminado
  });
  
  const [error, setError] = useState(null); // Estado de error
  const [successMessage, setSuccessMessage] = useState(null); // Para mostrar mensaje de éxito
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const confirmToggleTheme = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const handleConfirmToggleTheme = () => {
    toggleTheme()
    setShowModal(false);
  };


  const fetchUpdatedData = async () => {
    setError(null);
    setLoading(true);    
    setTimeout(() => setSuccessMessage(null), 5000);

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No autorizado');
        return;
      }

      const headers = { Authorization: `Token ${token}` };
      const response = await axios.get(PROFILE_URL, { headers });
      const updatedData = response.data.student_details[0];
      const timezone = response.data.timezone
    
      // Actualiza el estado con los datos recibidos
      setStudentDetails({
        // niche: updatedData.niche || '',
        niche: nichesOptions.some(option => option.value === updatedData.niche) ? updatedData.niche : 'Otro',
        prosp_country: updatedData.prosp_country || '',
        script: updatedData.script || '',
        service_offer: updatedData.service_offer || '',
        observations: updatedData.observations || '',
        timezone: timezone || '',
      });
      // Si no concuerda con la lista, guarda en setCustomNiche
      if (!nichesOptions.some(option => option.value === updatedData.niche)) {
        setCustomNiche(updatedData.niche);
        setSelectedNiche(true)
      }
        
      else{
        setCustomNiche('');
        setSelectedNiche(null)
      }

    } catch (error) {
      if (error.response && error.response.status === 401) {
          console.error('Unauthorized, removing token');
          localStorage.removeItem('token'); // Eliminar token si es un 401
          alert('Debes iniciar sesión nuevamente.');
          window.location.href = '/'; // Redirigir a /login
  
      } else {
      setError('Error al obtener los datos del perfil.');
    }} finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudentDetails({
      ...studentDetails,
      [name]: value
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage(null);
    setError(null);

    studentDetails.niche = studentDetails.niche !== 'Otro' ? studentDetails.niche : customNiche
    
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Token ${token}` };
      const response = await axios.put(PROFILE_URL, studentDetails, { headers });
      setSuccessMessage('Datos actualizados con éxito');
      fetchUpdatedData();
    } catch (error) {
      if (error.response && error.response.status === 401) {
          console.error('Unauthorized, removing token');
          localStorage.removeItem('token'); // Eliminar token si es un 401
          alert('Debes iniciar sesión nuevamente.');
          window.location.href = '/'; // Redirigir a /login
        } else {
      setError('Error al actualizar el perfil.');
    }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUpdatedData(); // Llama a la función para obtener los datos una vez montado el componente
  }, []);


  useEffect(() => {
    localStorage.setItem('theme', theme); // Guarda el tema en localStorage
    document.body.className = theme; // Aplica la clase 'light' o 'dark' al body

    
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light'); // Cambia entre light y dark
    navigate(0)
  };

  // Obtener todas las zonas horarias
  const timezones = moment.tz.names();

  const countryOptions = countriesData.countries.map((country) => ({
    label: country,
    value: country
  }));
  const timezoneOptions = timezones.map((timezone) => ({
    label: timezone,
    value: timezone
  }));
  const nichesOptions = niches.map((niche) => ({
    label: niche,
    value: niche
  }));

  return (
    <div className="container mt-4">
      <h1>Perfil de Usuario</h1>
      {error && <p className="text-danger">{error}</p>}
      {successMessage && <p className="text-success">{successMessage}</p>}
      {loading ?  (
                    <div className="d-flex justify-content-center mt-3">
                        <TailSpin color="#007bff" height={50} width={50} />
                    </div>
                        ) : (
                    <div className='my-4'>
            <form onSubmit={handleFormSubmit}>
        {/* <div className="mb-3">
          <label htmlFor="niche" className="form-label">Nicho</label>
          <input
            type="text"
            className={`form-control ${theme === "light" ? "" : "input-theme-black"}`}
            data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
            id="niche"
            name="niche"
            value={studentDetails.niche}
            onChange={handleInputChange}
            placeholder="Ingrese su nicho"
          />
        </div> */}

        <div className="mb-3 w-100">
        <label htmlFor="niche" className="form-label">Nicho (de no estar en la lista, selecciona "Otro" y especifica)</label>

            <div className="d-flex flex-row w-100">

              <div className='w-50'>

              <Select
                classNamePrefix={`react-select ${theme === "light" ? "" : "bg-dark"}`}
                data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                placeholder='Selecciona un Nicho'
                className={`form-control w-75 ${theme === "light" ? "" : "input-theme-black"}`}
                value={nichesOptions.find(option => option.value === studentDetails.niche) || { label: 'Otro', value: 'Otro' }}
                onChange={(selectedOption) => {
                  handleInputChange({
                    target: {
                      name: 'niche',
                      value: selectedOption ? selectedOption.value : ''
                    }
                  });
                  if (selectedOption.value === 'Otro'){setSelectedNiche(true);}
                  else {setSelectedNiche(null)

                  }
                }}
                theme={(selectTheme) => ({
                  ...selectTheme,
                  colors: {
                    ...selectTheme.colors,
                    primary25: theme === 'light' ? '#8c8787' : '#333', //option bg color focued
                    primary: theme === 'light' ? '#007bff' : '#ccc',
                    neutral30: "#8c8787", //control/borderColor(focused)
                    neutral80: "#8c8787", //input color
                    primary: theme === "light" ? "#8c8787" : "#8c8787", //option bg color selected
                    primary50: '#000', // option bg color active(enavled or available)
                    neutral90: "#000",

                    neutral0: theme === "light" ? "white" : "#343a40", //backgroud del menú
                    // neutral5: 'green',
                    // neutral10: 'green',
                    neutral20: theme === "light" ? "black" : "gray", //raya que separa input de la flecha
                    neutral40: theme === "light" ? "black" : "gray", // flecha hover
                    // neutral50: 'green',
                    // neutral60: 'green',
                    neutral70: 'green',
                  }
                })}
                styles={{
                  placeholder: (provided) => ({
                    ...provided,
                    color: theme === 'light' ? 'black' : 'white',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: theme === "light" ? "black" : "white",
                  }),
                  singleValue: (base) => ({ ...base, color: "black" }),
                  control: (provided, state) => ({
                    ...provided,
                    display: 'flex', 
                    gridArea: 'unset', 
                    minHeight: 'auto',
                    background: theme === "light" ? "white" : "#343a40",
                    boxShadow: state.isFocused ? null : null, // Removes weird border around container
                    borderColor: theme === "light" ? "white" : "#343a40",
                    "&:hover": {   borderColor: state.isFocused ? theme === "light" ? "white" : "#343a40" : ''}// Overwrittes the different states of border
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '10px', // Para hacer espacio y ver el texto seleccionado
                    color: "hotpink",
                    width: "100%"
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: theme === 'light' ? '#000' : '#fff', // Cambia el color del texto según el tema
                  }),
                  clearIndicator: () => null, // Oculta la "X"
                }}
                options={nichesOptions}
              />
              </div>
            {selectedNiche && (
                <div className='w-75 '>
                    <input
                        type="text"
                        id="customNiche"
                        value={customNiche}
                        onChange={(e) => {setCustomNiche(e.target.value);
                        handleInputChange(e);
                        }}
                        placeholder="Escribe tu nicho"
                        className={`form-control w-75 p-2 m-0 ${theme === "light" ? "" : "input-theme-black"}`}
                        data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                    />
                </div>
            )}
          </div>
          </div>



      <div className="mb-3 w-50"
      >
            <label htmlFor="prosp_country" className="form-label">Actual País de Prospección</label>
            <Select
              classNamePrefix={`react-select ${theme === "light" ? "" : "bg-dark"}`}
              data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
              placeholder='Selecciona un País'
              className={`form-control ${theme === "light" ? "" : "input-theme-black"}`}
              value={countryOptions.find(option => option.value === studentDetails.prosp_country)} 
              // onChange={handleInputChange}
              onChange={(selectedOption) => {
                handleInputChange({
                  target: {
                    name: 'prosp_country',
                    value: selectedOption ? selectedOption.value : ''
                  }
                });
              }}
              theme={(selectTheme) => ({
                ...selectTheme,
                colors: {
                  ...selectTheme.colors,
                  primary25: theme === 'light' ? '#8c8787' : '#333', //option bg color focued
                  primary: theme === 'light' ? '#007bff' : '#ccc',
                  neutral30: "#8c8787", //control/borderColor(focused)
                  neutral80: "#8c8787", //input color
                  primary: theme === "light" ? "#8c8787" : "#8c8787", //option bg color selected
                  primary50: '#000', // option bg color active(enavled or available)
                  neutral90: "#000",

                  neutral0: theme === "light" ? "white" : "#343a40", //backgroud del menú
                  // neutral5: 'green',
                  // neutral10: 'green',
                  neutral20: theme === "light" ? "black" : "gray", //raya que separa input de la flecha
                  neutral40: theme === "light" ? "black" : "gray", // flecha hover
                  // neutral50: 'green',
                  // neutral60: 'green',
                  neutral70: 'green',
                }
              })}
              styles={{
                placeholder: (provided) => ({
                  ...provided,
                  color: theme === 'light' ? 'black' : 'white',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  color: theme === "light" ? "black" : "white",
                }),
                singleValue: (base) => ({ ...base, color: "black" }),
                control: (provided, state) => ({
                  ...provided,
                  display: 'flex', 
                  gridArea: 'unset', 
                  minHeight: 'auto',
                  background: theme === "light" ? "white" : "#343a40",
                  boxShadow: state.isFocused ? null : null, // Removes weird border around container
                  borderColor: theme === "light" ? "white" : "#343a40",
                  "&:hover": {   borderColor: state.isFocused ? theme === "light" ? "white" : "#343a40" : ''}// Overwrittes the different states of border
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '10px', // Para hacer espacio y ver el texto seleccionado
                  color: "hotpink",
                  width: "100%"
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: theme === 'light' ? '#000' : '#fff', // Cambia el color del texto según el tema
                }),
                clearIndicator: () => null, // Oculta la "X"
              }}
              options={countryOptions}
            />

          </div>
        <div className="mb-3">
          <label htmlFor="script" className="form-label">Script</label>
          <textarea
            className={`form-control ${theme === "light" ? "" : "input-theme-black"}`}
            data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
            id="script"
            name="script"
            rows="3"
            value={studentDetails.script}
            onChange={handleInputChange}
            placeholder="Ingrese su script"
          ></textarea>
        </div>

        <div className="mb-3">
          <label htmlFor="service_offer" className="form-label">Oferta de Servicio</label>
          <textarea
            className={`form-control ${theme === "light" ? "" : "input-theme-black"}`}
            data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
            id="service_offer"
            name="service_offer"
            rows="3"
            value={studentDetails.service_offer}
            onChange={handleInputChange}
            placeholder="Ingrese su oferta de servicio"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="observations" className="form-label">Observaciones o Comentarios</label>
          <textarea
            className={`form-control mb-3 ${theme === "light" ? "" : "input-theme-black"}`}
            data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
            id="observations"
            name="observations"
            rows="3"
            value={studentDetails.observations}
            onChange={handleInputChange}
            placeholder="Ingrese observaciones o comentarios"
          ></textarea>

        <div className="mb-3 w-50">
          <label htmlFor="timezone" className="form-label">Tu zona horaria</label>
            <Select
              classNamePrefix={`react-select ${theme === "light" ? "" : "bg-dark"}`}
              data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
              placeholder='Ingresa tu Zona horaria'
              className={`form-control ${theme === "light" ? "" : "input-theme-black"}`}
              value={timezoneOptions.find(option => option.value === studentDetails.timezone)} 
              onChange={(selectedOption) => {
                handleInputChange({
                  target: {
                    name: 'timezone',
                    value: selectedOption ? selectedOption.value : ''
                  }
                });
              }}
              theme={(selectTheme) => ({
                ...selectTheme,
                colors: {
                  ...selectTheme.colors,
                  primary25: theme === 'light' ? '#8c8787' : '#333', //option bg color focued
                  primary: theme === 'light' ? '#007bff' : '#ccc',
                  neutral30: "#8c8787", //control/borderColor(focused)
                  neutral80: "#8c8787", //input color
                  primary: theme === "light" ? "#8c8787" : "#8c8787", //option bg color selected
                  primary50: '#000', // option bg color active(enavled or available)
                  neutral90: "#000",

                  neutral0: theme === "light" ? "white" : "#343a40", //backgroud del menú
                  // neutral5: 'green',
                  // neutral10: 'green',
                  neutral20: theme === "light" ? "black" : "gray", //raya que separa input de la flecha
                  neutral40: theme === "light" ? "black" : "gray", // flecha hover
                  // neutral50: 'green',
                  // neutral60: 'green',
                  neutral70: 'green',
                }
              })}
              styles={{
                placeholder: (provided) => ({
                  ...provided,
                  color: theme === 'light' ? 'black' : 'white',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  color: theme === "light" ? "black" : "white",
                }),
                singleValue: (base) => ({ ...base, color: "black" }),
                control: (provided, state) => ({
                  ...provided,
                  display: 'flex', 
                  gridArea: 'unset', 
                  minHeight: 'auto',
                  background: theme === "light" ? "white" : "#343a40",
                  boxShadow: state.isFocused ? null : null, // Removes weird border around container
                  borderColor: theme === "light" ? "white" : "#343a40",
                  "&:hover": {   borderColor: state.isFocused ? theme === "light" ? "white" : "#343a40" : ''}// Overwrittes the different states of border
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '10px', // Para hacer espacio y ver el texto seleccionado
                  color: "hotpink",
                  width: "100%"
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: theme === 'light' ? '#000' : '#fff', // Cambia el color del texto según el tema
                }),
                clearIndicator: () => null, // Oculta la "X"
              }}
              options={timezoneOptions}
            />
         
        </div>

        </div>

        <button type="submit" className="btn btn-primary">Guardar Cambios</button>
      </form> 
            <div className="theme-toggle">
                            <FontAwesomeIcon icon={faSun} size="2x" 
                            className={`m-2 ${theme === "light" ? "" : "text-secondary"}`}
                            />
                            <input
                                type="checkbox"
                                id="toggle"
                                // className="toggle-checkbox"
                                className={`toggle-checkbox ${theme === "light" ? "" : "text-secondary"}`}
                                data-bs-theme={`${theme === "light" ? "light" : "dark"}`}

                                checked={theme === 'dark'}
                                onChange={confirmToggleTheme} // Al cambiar el estado, alternar el tema
                            />
                            <label htmlFor="toggle" className="toggle-label">
                                <span className="toggle-icon"></span>
                            </label>
                            <FontAwesomeIcon icon={faMoon} size="2x" 
                                className={`m-2  ${theme === "light" ? "" : "text-secondary"}`}
                            />
                      </div>
                    </div>
                  )
                }
                <Modal 
                  show={showModal} 
                  onHide={handleClose} 
                  centered
                  className={`${theme === 'dark' ? 'modal-dark' : 'modal-light'} custom-modal`} // Clase personalizada
                  >
                    <Modal.Header 
                    closeButton 
                    className={`${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'} custom-modal-header`} // Añadir clase
                    >
                        <Modal.Title>Confirmar Cambio de tema</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}>
                        ¿Estás seguro que deseas cambiar el tema? <br /> Se recargará la página.
                    </Modal.Body>
                    <Modal.Footer className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant="danger" onClick={handleConfirmToggleTheme}>
                            Cambiar tema
                        </Button>
                    </Modal.Footer>
                </Modal>
        
    </div>
    
  );
};

export default Profile;
